import { useContext } from 'react';
import { Box } from '@mui/material';

import { AdvertiserContext } from '../../AdvertiserContext';
import { CampaignWizardContext } from '../../hooks/useCampaignWizard';
import CampaignPageHeader from './partials/CampaignPageHeader';
import CampaignWizard from './partials/CampaignWizard/CampaignWizard';
import CampaignWizardSubmissionConfirmation from './partials/CampaignWizard/CampaignWizardSubmissionConfirmation';
import CampaignWizardSubmissionLoadingConfirmation from './partials/CampaignWizard/CampaignWizardSubmissionLoadingConfirmation';
import useCampaignWizard from '../../hooks/useCampaignWizard';

export default function CampaignCreatePage() {
    const { advertiserContext } = useContext(AdvertiserContext);

    const campaignWizard = useCampaignWizard({
        dealer: advertiserContext.dealer,
    });
    const { campaign, wasRecentlyLoaded, wasRecentlySubmitted } = campaignWizard;

    return (
        <CampaignWizardContext.Provider value={campaignWizard}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                    pb: 3,
                }}
            >
                {!wasRecentlySubmitted && (
                    <>
                        <CampaignPageHeader />

                        <Box>
                            <CampaignWizard campaignWizard={campaignWizard} />
                        </Box>
                    </>
                )}

                {wasRecentlySubmitted && (
                    <>
                        {!wasRecentlyLoaded && <CampaignWizardSubmissionLoadingConfirmation campaign={campaign} />}

                        {wasRecentlyLoaded && <CampaignWizardSubmissionConfirmation campaign={campaign} />}
                    </>
                )}
            </Box>
        </CampaignWizardContext.Provider>
    );
}
