import { useMemo, useRef } from 'react';
import { deepmerge } from '@mui/utils';
import { Theme } from '@mui/material/styles';
import { useRadioGroup } from '@mui/material';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardProps,
    Radio,
    RadioProps,
    RadioGroupProps,
    Stack,
    Typography,
    SvgIconProps,
} from '@mui/material';

export type RadioCardGroupProps = {
    disabled?: boolean;
} & RadioGroupProps;

export interface RadioCardProps extends RadioProps {
    // checked: boolean;
    // icon?: React.ReactElement;
    label?: React.ReactElement | string | undefined;
    description?: React.ReactElement | string | undefined;
    direction?: 'row' | 'column';
    // icon?: React.ReactElement<SvgIconProps> | undefined;
    LabelIcon?: React.ComponentType<SvgIconProps> | undefined;
    CardProps?: CardProps;
}

export function RadioCard(props: RadioCardProps) {
    const { label, description, direction, LabelIcon, CardProps, ...radioProps } = props;

    const radioGroup = useRadioGroup();
    const radioInputRef = useRef<HTMLInputElement>(null);

    const checked = useMemo((): boolean => {
        if (radioGroup) {
            return radioGroup.value === props.value;
        }

        return false;
    }, [props.value, radioGroup]);

    const handleClick = () => {
        if (radioInputRef && radioInputRef.current) {
            radioInputRef.current.click();
        }
    };

    const color = useMemo(() => {
        return props.color === undefined ? 'primary' : props.color;
    }, [props.color]);

    const textColor = useMemo(() => {
        if (props.disabled) {
            return 'textDisabled';
        }
        return checked ? color : '';
    }, [checked, color, props.disabled]);

    const inheritColor = useMemo(() => {
        return checked ? color : 'action';
    }, [checked, color]);

    const iconColor = useMemo(() => {
        if (props.disabled) {
            return 'disabled';
        }
        if (checked) {
            if (color !== 'default') {
                return color;
            }
        }
        return 'action';
    }, [checked, color, props.disabled]);

    const CardSxProps = deepmerge(CardProps?.sx ?? {}, {
        '&.MuiCard-root': {
            borderColor: (theme: Theme) => {
                if (!inheritColor || ['action', 'inherit'].includes(inheritColor)) {
                    return '';
                }
                if (props?.disabled) {
                    return '';
                }
                return `${inheritColor}.main`;
            },
        },
    });

    return (
        <Card variant="outlined" {...CardProps} sx={CardSxProps}>
            <CardActionArea onClick={handleClick} disabled={props.disabled ?? false}>
                <CardContent sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={1}>
                        <Stack direction={direction ?? 'row'} gap={direction === 'row' ? 1 : 2}>
                            {LabelIcon && (
                                <Box>
                                    <LabelIcon color={iconColor} />
                                </Box>
                            )}
                            <Stack direction="column" gap={1}>
                                <Typography variant="subtitle2" color={textColor}>
                                    {label}
                                </Typography>
                                <Typography variant="caption" color={textColor}>
                                    {description}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Radio
                            checked={checked}
                            size="small"
                            {...radioProps}
                            disableRipple={true}
                            inputRef={radioInputRef}
                        />
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
