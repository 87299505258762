import { useEffect, useMemo } from 'react';
import { Chip } from '@mui/material';

import { hasRootCategory, getChildCategories, getParentCategory } from './CategoryAutocomplete';
import { Category, CategoryAutcompleteProps, Option } from './CategoryAutocomplete';
import CategoryAutocomplete from './CategoryAutocomplete';

export class ProductCategory implements Category<ProductCategory> {
    id: string = '';
    parentId: string | null = null;
    name: string = '';
    children?: ProductCategory[];
}

// export function toProductCategory(option: any) {
//     return {
//         id: option.id,
//         name: option.name,
//         parentId: option.parentId,
//     };
// }

export type ProductCategoryAutocompleteProps = {
    selections: ProductCategory[];
    fetchSelections?: () => void;
} & Omit<CategoryAutcompleteProps, 'categories'>;

export default function ProductCategoryAutocomplete(props: ProductCategoryAutocompleteProps) {
    const { selections, fetchSelections, selectedCategories, onSelectedCategories, ...autocompleteProps } = props;

    const filteredSelections = useMemo((): ProductCategory[] => {
        const rootCategory = selectedCategories.find((pc: ProductCategory) => !pc.parentId);

        if (rootCategory?.id) {
            return selections.filter(
                (o: ProductCategory) => o.id === rootCategory.id || o.parentId === rootCategory.id
            );
        }

        return selections;
    }, [selections, selectedCategories]);

    useEffect(() => {
        if (fetchSelections) {
            fetchSelections();
        }
    }, [fetchSelections]);

    return (
        <CategoryAutocomplete
            label="Product Category"
            getOptionDisabled={(option: Option) => {
                const rootCategory = selectedCategories.find((pc: ProductCategory) => !pc.parentId);

                if (rootCategory) {
                    if (!option.parentId) {
                        return option.id !== rootCategory.id;
                    }

                    return option.parentId !== rootCategory.id;
                }

                return false;
            }}
            renderTags={(tagValue, getTagProps) => {
                const tagValueCount = tagValue.length - 1;

                const getChipLabel = (option: Option): string => {
                    if (tagValueCount > 0) {
                        return `${option.name} (${tagValueCount} selected)`;
                    }

                    return option.name;
                };

                return tagValue
                    .filter((option: Option) => !option.parentId)
                    .map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return <Chip key={key} label={getChipLabel(option)} {...tagProps} onDelete={undefined} />;
                    });
            }}
            {...autocompleteProps}
            required={props.required && selections.length > 0}
            categories={filteredSelections}
            selectedCategories={selectedCategories}
            onSelectedCategories={(categories, reason) => {
                switch (reason) {
                    case 'selectOption':
                        if (!hasRootCategory(categories)) {
                            if (categories.length > 0) {
                                let parentCategory = getParentCategory(categories[0], selections);

                                if (parentCategory) {
                                    categories.unshift(parentCategory);
                                }
                            }
                        }

                        const lastCategory = categories[categories.length - 1];

                        if (lastCategory && !lastCategory.parentId) {
                            const childCategories = getChildCategories(lastCategory, selections);

                            if (childCategories?.length) {
                                categories.push(...childCategories);
                            }
                        }
                        break;

                    case 'removeOption':
                        if (!hasRootCategory(categories)) {
                            categories.splice(0, categories.length);
                        }
                        break;
                }

                props.onSelectedCategories(categories, reason);
            }}
        />
    );
}
