import './styles.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import Box from '@mui/material/Box';
import buffer from '@turf/buffer';
import { point } from '@turf/helpers';
import { Feature, Polygon } from 'geojson';
import { useEffect, useRef, useState } from 'react';
import ReactMap, { Layer, MapLayerMouseEvent, MapRef, Marker, NavigationControl, Source } from 'react-map-gl';
import BusyOverlay from './BusyOverlay';
import DrawControl from './DrawControl';
import { BoundaryStyles, fill, line } from './layers';
import {
    codeToCenterPoint,
    polygonToBoundaries,
    ZipFeature,
    ZipFeatureCollection,
    ZipFeatureProperties,
} from './resolver';

type toDisplayFeaturesParams = Parameters<typeof MapboxDraw.modes.simple_select.toDisplayFeatures>;

/** The maximum zoom level at which the polygon control will be available */
const polygonControlMaxZoom = 8;

const defaultLocation = { center: [-97.9222112121185, 39.3812661305678] };

const simple_select = {
    ...MapboxDraw.modes.simple_select,
    onDrag: () => {
        // no-op
    },
    clickOnVertex: () => {
        // no-op
    },
    clickOnFeature: () => {
        // no-op
    },
    toDisplayFeatures: (
        _state: toDisplayFeaturesParams[0],
        geojson: toDisplayFeaturesParams[1],
        display: toDisplayFeaturesParams[2]
    ) => {
        display(geojson);
    },
};

export type ZipCode = {
    code: string;
    population: number;
};

/**
 * Convert a GeoJSON feature to a ZipCode object
 */
export function featureToZipCode(feature: Feature<Polygon, ZipFeatureProperties>): ZipCode {
    const { properties } = feature;
    return {
        code: properties[properties.key] as string,
        population: properties.population as number,
    };
}

type ReactMapProps = React.ComponentProps<typeof ReactMap>;

type MapProps = ReactMapProps & {
    /**
     * The base URL where the data files will be requested from
     */
    baseUrl?: string;

    /**
     * The country for the code lookup; defaults to 'United States'
     */
    country?: string;

    /**
     * A zip code to center the map on
     */
    code: string;
    zipCodes: string;

    /**
     * The radius in miles to show in the viewport from the center; defaults to
     * 1 mile
     */
    radius?: number;

    /**
     * If true, show a marker on the map for the set center point
     */
    marker?: React.ReactElement;

    /**
     * The styles to use for the zip code boundaries
     */
    boundaryStyles?: BoundaryStyles;
    displayBoundariesOnStart?: boolean;
    onZipHover?: (code: string | null, population: string | null) => void;

    /**
     * A function called when the user clicks a zip code boundary
     */
    onZipClick: (code: ZipCode) => void;

    /**
     * A function called when a zip code boundaries have been loaded
     */
    onZipsLoaded: (results: ZipCode[]) => void;

    /**
     * An optional funnction that will be called to get the boundaries for the
     * drawn polygon.
     */
    getBoundaries?: (polygon: GeoJSON.Polygon) => Promise<{
        boundaries: ZipFeatureCollection;
    }>;

    /**
     * An optioanl function that will be called to get the geographic center
     * point of a zip code.
     */
    getCenterPoint?: (country: string, code: string) => Promise<{ center: [number, number] }>;
    onGetCenterPointError?: () => void;

    autoSelectBoundariesOnEmpty?: boolean;
    boundaryType?: 'ZIPCODE' | 'TARGET_ZIPCODE';
};

function Map(props: MapProps) {
    const {
        baseUrl: baseUrlProp = '',
        country = 'United States',
        code,
        zipCodes,
        radius = 1,
        marker,
        onZipClick,
        onZipHover,
        onZipsLoaded,
        getBoundaries,
        getCenterPoint,
        boundaryStyles,
        displayBoundariesOnStart,
        autoSelectBoundariesOnEmpty = true,
        boundaryType = 'ZIPCODE',
        onGetCenterPointError,
        mapboxAccessToken,
        ...mapboxProps
    } = props;
    const [startingPoint, setStartingPoint] = useState<{
        center: [number, number];
    }>();
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const [boundaries, setBoundaries] = useState<ZipFeatureCollection>();
    const [showPolygonControl, setShowPolygonControl] = useState(true);
    const [busy, setBusy] = useState(false);
    const mapRef = useRef<MapRef>(null);
    const [viewState, setViewState] = useState({
        longitude: 0,
        latitude: 0,
        zoom: 8,
    });

    // Create stable references to function props so that we won't rerun
    // effects when inline functions are used
    const getCenterPointRef = useRef(getCenterPoint);
    getCenterPointRef.current = getCenterPoint;
    const onZipClickRef = useRef(onZipClick);
    onZipClickRef.current = onZipClick;
    const onZipsLoadedRef = useRef(onZipsLoaded);
    onZipsLoadedRef.current = onZipsLoaded;
    const onGetCenterPointErrorRef = useRef(onGetCenterPointError);
    onGetCenterPointErrorRef.current = onGetCenterPointError;

    const baseUrl =
        baseUrlProp === '' || baseUrlProp.charAt(baseUrlProp.length - 1) === '/' ? baseUrlProp : `${baseUrlProp}/`;

    // Called when the user has created a bounding polygon
    const loadZipBoundaries = async ({ draw, features }: { draw?: MapboxDraw; features: GeoJSON.Feature[] }) => {
        setBusy(true);

        try {
            const id = features[0].id;

            if (draw) {
                const currentPolygon = draw
                    .getAll()
                    .features.map((feature) => feature.id as string)
                    .filter((feature) => feature !== id);
                draw.delete(currentPolygon);
            }

            const polygon = features[0] as Feature<Polygon>;

            let result: { boundaries: ZipFeatureCollection };
            if (getBoundaries) {
                result = await getBoundaries(polygon.geometry);
            } else {
                result = await polygonToBoundaries(polygon.geometry, async (path) => {
                    const res = await fetch(`${baseUrl}${path}.json`);
                    return await res.json();
                });
            }

            if (JSON.stringify(polygon.geometry.coordinates[0][0]) === JSON.stringify(defaultLocation.center)) {
                setBoundaries(undefined);
            } else {
                setBoundaries(result.boundaries);
            }
            onZipsLoadedRef.current(result.boundaries.features.map(featureToZipCode));
        } catch (error) {
            console.warn('Error fetching boundaries', error);
        } finally {
            setBusy(false);
        }
    };

    const hoverRef = useRef<string | null>(null);

    const onMouseMove = (e: MapLayerMouseEvent) => {
        if (!onZipHover || !boundaries) {
            return;
        }

        const map = e.target;
        const [feature] = map.queryRenderedFeatures(e.point, {
            layers: ['fill'],
        });
        const { properties } = feature ?? {};
        const code = properties ? properties[properties.key] : null;
        const population = properties ? properties.population : null;
        const key = properties ? `${code}:${population}` : null;
        if (hoverRef.current !== key) {
            hoverRef.current = key;
            onZipHover(code, population);
        }
    };

    const onClick = (e: MapLayerMouseEvent) => {
        if (mapLoaded) {
            const map = e.target;

            if (boundaries) {
                const [feature] = map.queryRenderedFeatures(e.point, {
                    layers: ['fill'],
                }) as ZipFeature[];
                if (feature) {
                    onZipClick(featureToZipCode(feature));
                }
            }
        }
    };

    // New zipcodes were set -- update the selected boundaries
    useEffect(() => {
        if (!mapLoaded || !boundaries) {
            return;
        }

        const map = mapRef.current;
        if (!map) {
            return;
        }

        boundaries.features.forEach((feature) => {
            const zip = feature.properties[feature.properties.key] as string;
            const selected = (!zipCodes && autoSelectBoundariesOnEmpty) || zipCodes.includes(zip);
            map.setFeatureState({ source: 'zipcodes', id: feature.id }, { disabled: !selected });
        });
    }, [autoSelectBoundariesOnEmpty, mapLoaded, boundaries, zipCodes]);

    // Create a stable ref for the fetchStartingPoint function so it won't
    // trigger useEffect updates
    const fetchStartingPointRef = useRef<(country: string, code: string) => Promise<void>>();
    fetchStartingPointRef.current = async (country, code) => {
        let result: { center: [number, number] } | undefined;
        if (getCenterPointRef.current) {
            result = await getCenterPointRef.current(country, code);
        } else {
            result = await codeToCenterPoint(country, code, async (path) => {
                const res = await fetch(`${baseUrl}${path}.json`);
                return await res.json();
            });
        }

        setStartingPoint(result);

        if (result) {
            const { center } = result;
            setViewState((vs) => ({
                ...vs,
                longitude: center[0],
                latitude: center[1],
            }));

            if (displayBoundariesOnStart) {
                if (boundaryType === 'TARGET_ZIPCODE' || zipCodes.length === 0) {
                    const p = point(center);
                    let buffered = buffer(p, radius, { units: 'miles' });
                    if (JSON.stringify(result) === JSON.stringify(defaultLocation)) {
                        const updatedCoordinate = {
                            ...buffered.geometry,
                            coordinates: [
                                [
                                    [-97.9222112121185, 39.3812661305678],
                                    [-97.9222112121185, 39.3812661305678],
                                ],
                            ],
                        };
                        const updatedBuffered = { ...buffered, geometry: updatedCoordinate };
                        loadZipBoundaries({ features: [updatedBuffered] });
                    } else {
                        loadZipBoundaries({ features: [buffered] });
                    }
                } else {
                    const zipCodePayload = zipCodes.split(',').map(String);
                    const res = await fetch('/api/geographicTargeting/data/boundaries', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(zipCodePayload),
                    });
                    let buffered = await res.json();
                    setBoundaries({
                        type: 'FeatureCollection',
                        features: buffered.features.map((item: ZipFeature, id: number) => {
                            const properties = {
                                ...item.properties,
                                key: 'ZCTA5CE10',
                            };
                            return { ...item, id, properties };
                        }),
                    });

                    setBusy(false);
                }
            }
        }
    };

    // Map location inputs changed -- refetch the starting point
    useEffect(() => {
        // The starting point code uses the maxbox access token for geocoding.
        // Don't run it if we don't yet have a token. We still need the token
        // as an effect dependency to ensure this code is run when all three
        // of the token, code, and country are available.
        if (!mapboxAccessToken) {
            return;
        }

        fetchStartingPointRef
            .current?.(country, code)
            .catch((error) => {
                console.log(error);
                onGetCenterPointErrorRef.current?.();
            })
            .finally(() => {
                setBusy(false);
            });
        // }, [mapboxAccessToken, code, country, radius, zipCodes]);
    }, [mapboxAccessToken, code, country, radius]);

    // Track the container ref in a state variable. When the container ref
    // is set, an effect will be triggered that will attach a resize observer.
    // The observer will be detached when the component unmounts.
    const [container, setContainer] = useState<HTMLElement>();

    useEffect(() => {
        if (!container) {
            return;
        }

        // Notify the map that it should resize whenever its container resizes
        const observer = new ResizeObserver(() => {
            mapRef.current?.resize();
        });
        observer.observe(container);

        return () => {
            observer.disconnect();
        };
    }, [container]);

    return (
        <Box width="100%" height="100%" ref={setContainer} position="relative">
            {startingPoint && mapboxAccessToken && (
                <ReactMap
                    mapboxAccessToken={mapboxAccessToken}
                    {...mapboxProps}
                    {...viewState}
                    onMove={(evt) => setViewState(evt.viewState)}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    ref={mapRef}
                    onLoad={() => {
                        setMapLoaded(true);
                    }}
                    interactiveLayerIds={['fill']}
                    onClick={onClick}
                    onMouseMove={onMouseMove}
                    onZoomEnd={({ viewState: { zoom } }) => {
                        setShowPolygonControl(zoom >= polygonControlMaxZoom);
                    }}
                >
                    <NavigationControl showCompass={false} position="top-right" />

                    {showPolygonControl && (
                        <DrawControl
                            position="top-left"
                            displayControlsDefault={false}
                            controls={{ polygon: true }}
                            onCreate={loadZipBoundaries}
                            onModeChange={(event) => {
                                if (event.mode === 'draw_polygon') {
                                    setBoundaries(undefined);
                                }
                            }}
                            modes={{ ...MapboxDraw.modes, simple_select }}
                        />
                    )}

                    {mapLoaded && marker && (
                        <Marker longitude={startingPoint.center[0]} latitude={startingPoint.center[1]} anchor="bottom">
                            {marker}
                        </Marker>
                    )}

                    {mapLoaded && (
                        <Source
                            id="zipcodes"
                            type="geojson"
                            data={
                                boundaries || {
                                    type: 'FeatureCollection',
                                    features: [],
                                }
                            }
                        >
                            <Layer {...fill(boundaryStyles)} />
                            <Layer {...line(boundaryStyles)} />
                        </Source>
                    )}
                </ReactMap>
            )}

            <BusyOverlay visible={busy || !startingPoint || !mapboxAccessToken} container={container} />
        </Box>
    );
}

export default Map;
