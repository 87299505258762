import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { ZipCode } from '../Map/Map';
import Map from '../Map/Map';
import useMapBoundaries from '../../hooks/useMapBoundaries';

export type { ZipCode };

const boundaryStyles = {
    'background-color': '#1fbdf6',
    opacity: 0.5,
    'line-color': '#0004ae',
    'line-opacity': 0.6,
    selected: {
        'background-color': 'yellow',
        'line-color': '#0004ae',
        'line-opacity': 0.6,
    },
};

export default function ZipCodeMap(props: {
    zipCode: string;
    zipCodes: string;
    targetZipCodes: string;
    radius: number;
    onZipsLoaded(results: ZipCode[]): void;
    onZipClick(zip: ZipCode): void;
    reuseMaps?: boolean;
}) {
    const { onZipsLoaded, onZipClick, radius, zipCode, targetZipCodes, zipCodes } = props;
    const [token, setToken] = useState<string>('');

    const country = 'United States';
    const countryCode = 'US';
    const baseUrl = '/api/geographicTargeting';

    useEffect(() => {
        axios.get('/api/geographicTargeting/token').then((response) => {
            setToken(response.data);
        });
    }, []);

    const getCenterPoint = useCallback(
        async (country: string, zipCode: string) => {
            const response = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?country=${countryCode}&limit=1&proximity=ip&access_token=${token}`
            );
            const data = await response.json();

            if (data.features.length > 0) {
                return { center: data.features[0].center };
            }

            // USA
            return { center: [-97.9222112121185, 39.3812661305678] };
        },
        [token]
    );

    const getCenterPointByGeocoder = useCallback(async () => {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?country=${countryCode}&limit=1&proximity=ip&access_token=${token}`
        );
        const data = await response.json();

        if (data.features.length > 0) {
            return { center: data.features[0].center };
        }

        // USA
        return { center: [-97.9222112121185, 39.3812661305678] };
    }, [countryCode, zipCode, token]);

    const { getBoundaries } = useMapBoundaries({
        baseUrl: baseUrl,
        country: country,
        radius: radius,
        getCenterPoint: getCenterPoint,
        targetZipCodes: targetZipCodes,
        zipCodes: zipCodes,
    });

    return (
        <Map
            autoSelectBoundariesOnEmpty={false}
            displayBoundariesOnStart={true}
            boundaryType="TARGET_ZIPCODE"
            reuseMaps={props.reuseMaps}
            baseUrl={baseUrl}
            onZipClick={onZipClick}
            onZipsLoaded={onZipsLoaded}
            country={country}
            code={zipCode}
            zipCodes={zipCodes}
            radius={radius}
            mapboxAccessToken={token}
            getBoundaries={getBoundaries}
            getCenterPoint={getCenterPointByGeocoder}
            boundaryStyles={boundaryStyles}
        />
    );
}
