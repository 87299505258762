import {
    createContext,
    FormEvent,
    FormEventHandler,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AdvertiserContext } from '../AdvertiserContext';
import { CampaignChangeRequest } from '../types/Campaign';
import { Dealer } from '../types/Dealer';
import { Stepper } from './useStepper';
import { UserContext } from '../App';
import {
    CampaignGeographyTargeting,
    CampaignZipCodes,
    useCampaignGeographyTargeting,
    useCampaignZipCodes,
} from '../hooks/useCampaign';
import { parseZipCodes } from './useZipCodes';
import { toCampaignAdGroupGeoLocations } from './useLocations';
import { useAppLoader, AppLoaderState } from './useAppLoader';
import { useInfoMessage } from './useInfoMessage';
import { CampaignDefaultSettings, useCampaignDefaultSettings } from './useCampaignDefaultSettings';
import { useCampaignUpdatePayload } from './useCampaign';
import useAudienceSources, { AudienceSources } from './useAudienceSources';
import useCodes, { Codes } from './useCodes';
import useInventorySources, { InventorySources } from './useInventorySources';
import useLocations, { Locations } from './useLocations';
import useProductCategories, { ProductCategories } from './useProductCategories';
import useStepper from './useStepper';
import ApiService from '../ApiService';
import Utils from '../components/Utils';

export const MAX_FILE_SIZE = 314572800;

export const STEP_CAMPAIGN_SETUP = 0;
export const STEP_CAMPAIGN_GEOGRAPHY = 1;
export const STEP_CAMPAIGN_CREATIVE = 2;
export const STEP_CAMPAIGN_REVIEW = 3;

export class CampaignWizardContextValue {
    $audienceSources: AudienceSources | null = null;
    $inventorySources: InventorySources | null = null;
    $locations: Locations | null = null;
    $productCategories: ProductCategories | null = null;

    $loader: AppLoaderState | null = null;
    $campaignGeographyTargeting: CampaignGeographyTargeting | null = null;
    $campaignZipCodes: CampaignZipCodes | null = null;
    $campaignDefaultSettings: CampaignDefaultSettings | null = null;

    $bidStrategyCodes: Codes | null = null;
    $campaignTypeCodes: Codes | null = null;
    $goalTypeCodes: Codes | null = null;
    $kpiTypeCodes: Codes | null = null;
    $mediaTypeCodes: Codes | null = null;
    $strategyTypeCodes: Codes | null = null;

    campaign: CampaignChangeRequest = new CampaignChangeRequest(undefined);
    campaignBidStrategyLabel: string = '';
    campaignBudgetManagementStrategyLabel: string = '';
    dealer: Dealer | null = null;
    deleteDraftCampaign: Function = (): Promise<any> => Promise.resolve({});
    fetchCampaign: Function = (): void => {};
    files: File[] = [];
    generateCampaignName: Function = (): string => '';
    getCampaignName: Function = (): string => '';
    handleSubmit: FormEventHandler<HTMLFormElement> = (event: FormEvent<HTMLFormElement>): void => {};
    hasError: Function = (field: string): boolean => false;
    hasFormError: boolean = false;
    ignoreDuplicates: boolean = false;
    isCompletedCampaign: boolean = false;
    isDraftCampaign: boolean = false;
    isInvalidField: Function = (field: string): boolean => false;
    isNewCampaign: boolean = true;
    isValidCampaign: Function = (): boolean => false;
    isValidField: Function = (field: string): boolean => false;
    isValidStep: Function = (step: number): boolean => false;
    resetCampaignWizard: Function = (): void => {};
    saveInProgress: boolean = false;
    setCampaign: Function = (campaign: CampaignChangeRequest): void => {};
    setHasFormError: Function = (hasFormError: boolean): void => {};
    setIgnoreDuplicates: Function = (ignoreDuplicates: boolean): void => {};
    setIsCompletedCampaign: Function = (ignoreDuplicates: boolean): void => {};
    setFiles: Function = (files: File[]): void => {};
    setSaveInProgress: Function = (saveInProgress: boolean): void => {};
    setWasRecentlyCreated: Function = (wasRecentlyCreated: boolean): void => {};
    setWasRecentlyUpdated: Function = (wasRecentlyUpdated: boolean): void => {};
    setWasRecentlyLoaded: Function = (wasRecentlyLoaded: boolean): void => {};
    setWasRecentlySubmitted: Function = (wasRecentlySubmitted: boolean): void => {};
    stepper: Stepper = new Stepper();
    updateCampaign: Function = (): Promise<any> => Promise.resolve({});
    updateDraftCampaign: Function = (): Promise<any> => Promise.resolve({});
    validateCampaign: Function = (): boolean => false;
    wasRecentlyCreated: boolean = false;
    wasRecentlyUpdated: boolean = false;
    wasRecentlyLoaded: boolean = false;
    wasRecentlySubmitted: boolean = false;
}

export const CampaignWizardContext = createContext<CampaignWizardContextValue>(new CampaignWizardContextValue());

export function useCampaignWizardContext() {
    return useContext(CampaignWizardContext);
}

type CampaignUpdatePayload = Omit<CampaignChangeRequest, 'activationStatus'>;

export type CampaignWizardProps = {
    dealer: Dealer | null;
    campaignId?: number | null;
    campaignChangeRequestId?: number | null;
};

export default function useCampaignWizard(props: CampaignWizardProps) {
    const { campaignId, campaignChangeRequestId, dealer } = props;
    const { setDealer } = useContext(AdvertiserContext);
    const { showInfoMessage } = useInfoMessage();
    const { userContext } = useContext(UserContext);
    const navigate = useNavigate();

    const getNewCampaignInstance = (): CampaignChangeRequest => {
        let newCampaignInstance = new CampaignChangeRequest(dealer?.id, dealer);
        newCampaignInstance.startDate = new Date();
        newCampaignInstance.endDate = Utils.getMonthEnd(0);
        newCampaignInstance.campaignName = '';
        newCampaignInstance.mediaType = '';
        newCampaignInstance.strategyType = '';
        newCampaignInstance.bidStrategy = '';
        newCampaignInstance.goal = '';
        newCampaignInstance.kpi = '';
        return newCampaignInstance;
    };

    const [campaign, setCampaign] = useState<CampaignChangeRequest>(getNewCampaignInstance());
    const [files, setFiles] = useState<File[]>([]);
    const [hasFormError, setHasFormError] = useState<boolean>(false);
    const [ignoreDuplicates, setIgnoreDuplicates] = useState<boolean>(false);
    const [isCompletedCampaign, setIsCompletedCampaign] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

    const [wasRecentlyCreated, setWasRecentlyCreated] = useState<boolean>(false);
    const [wasRecentlyUpdated, setWasRecentlyUpdated] = useState<boolean>(false);
    const [wasRecentlyLoaded, setWasRecentlyLoaded] = useState<boolean>(false);
    const [wasRecentlySubmitted, setWasRecentlySubmitted] = useState<boolean>(false);

    const $audienceSources = useAudienceSources({ dealer: dealer ?? campaign.dealer });
    const $inventorySources = useInventorySources({ dealer: dealer ?? campaign.dealer });
    const $locations = useLocations({ dealer: dealer ?? campaign.dealer });
    const $productCategories = useProductCategories({ dealer: dealer ?? campaign.dealer });

    // Codes
    const $bidStrategyCodes = useCodes({ codeGroup: 'BidStrategy' });
    const $campaignTypeCodes = useCodes({ codeGroup: 'CampaignType' });
    const $goalTypeCodes = useCodes({ codeGroup: 'GoalType' });
    const $kpiTypeCodes = useCodes({ codeGroup: 'KpiType' });
    const $mediaTypeCodes = useCodes({ codeGroup: 'MediaType' });
    const $strategyTypeCodes = useCodes({ codeGroup: 'StrategyType' });

    const $loader = useAppLoader();
    const $campaignZipCodes = useCampaignZipCodes({ campaign });
    const $campaignGeographyTargeting = useCampaignGeographyTargeting({ campaign });
    const { fetchCampaignZipCodes } = $campaignZipCodes;
    const { fetchCampaignGeographyTargeting } = $campaignGeographyTargeting;

    const $campaignDefaultSettings = useCampaignDefaultSettings();
    const {
        fetchCampaignDefaultSettings,
        campaignDefaultSettings,
        applyCampaignDefaultSettings,
        campaignDefaultSettingCriteria,
        // setCampaignDefaultSettingCriteria,
    } = $campaignDefaultSettings;

    const { toCampaignUpdatePayload } = useCampaignUpdatePayload();

    useEffect(() => {
        if (campaign.mediaType === 'Audio') {
            setCampaign({
                ...campaign,
                gatewayAmazonDSPIsActive: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.mediaType]);

    useEffect(() => {
        if (fetchCampaignZipCodes) {
            fetchCampaignZipCodes();
        }
    }, [fetchCampaignZipCodes]);

    useEffect(() => {
        if (fetchCampaignGeographyTargeting) {
            fetchCampaignGeographyTargeting();
        }
    }, [fetchCampaignGeographyTargeting]);

    useEffect(() => {
        if ($locations?.locations?.length) {
            if (campaign?.adGroups?.length) {
                campaign.adGroups[0] = {
                    ...campaign.adGroups[0],
                    adGroupGeoLocations: toCampaignAdGroupGeoLocations({
                        locations: $locations.locations,
                        zipCodes: parseZipCodes(campaign.zipcodes),
                        adGroupGeoLocations: campaign.adGroups[0].adGroupGeoLocations ?? [],
                    }),
                };
            } else {
                campaign.adGroups.push({
                    adGroupGeoLocations: toCampaignAdGroupGeoLocations({
                        locations: $locations.locations,
                        zipCodes: parseZipCodes(campaign.zipcodes),
                        adGroupGeoLocations: [],
                    }),
                });
            }
        }

        setCampaign({
            ...campaign,
            adGroups: campaign.adGroups,
        });
    }, [campaign.zipcodes, $locations.locations]);

    // useEffect(() => {
    //     setCampaignDefaultSettingCriteria({
    //         ...campaignDefaultSettingCriteria,
    //         ...{
    //             mediaType: campaign.mediaType as string,
    //             goalType: campaign.goal as string,
    //         },
    //     });
    // }, [campaign.mediaType, campaign.goal]);

    const fetchCampaignDefaultSettingsCallback = useCallback(async (): Promise<void> => {
        if (fetchCampaignDefaultSettings) {
            $loader.setLoading(true);

            await fetchCampaignDefaultSettings(campaignDefaultSettingCriteria);

            setTimeout(() => {
                $loader.setLoading(false);
            }, 1000);
        }
    }, [fetchCampaignDefaultSettings, campaignDefaultSettingCriteria]);

    useEffect(() => {
        fetchCampaignDefaultSettingsCallback();
    }, [fetchCampaignDefaultSettingsCallback]);

    useEffect(() => {
        if (campaignDefaultSettings) {
            setCampaign({
                ...applyCampaignDefaultSettings(campaign, campaignDefaultSettings),
                status: null,
                statusDate: null,
            });
        }
    }, [campaignDefaultSettings]);

    useEffect(() => {
        if (!dealer) {
            resetCampaignWizard();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer]);

    const resetCampaignWizard = (): void => {
        setDealer(null);
        setCampaign(getNewCampaignInstance());
        setFiles([]);
        setHasFormError(false);
        setIgnoreDuplicates(false);
        setIsCompletedCampaign(false);
        setSaveInProgress(false);
        setWasRecentlyCreated(false);
        setWasRecentlyUpdated(false);
        setWasRecentlyLoaded(false);
        setWasRecentlySubmitted(false);

        stepper.resetStep();
    };

    const getCampaignName = (): string => {
        if (campaign.campaignName) {
            return campaign.campaignName;
        }

        return generateCampaignName(campaign);
    };

    const generateCampaignName = (_campaign: CampaignChangeRequest): string => {
        let campaignType: string = _campaign.campaignType as string;
        campaignType = campaignType ?? 'DCO';
        if (campaignType === 'DCO') {
            campaignType = 'Dynamic';
        } else if (campaignType === 'AdTag') {
            campaignType = 'Ad Tag';
        }

        return [_campaign.mediaType, campaignType, _campaign.strategyType]
            .filter((s: string | null | undefined) => s && s.length > 0)
            .join(' ');
    };

    const isNewCampaign = useMemo((): boolean => {
        return !campaign.campaignId;
    }, [campaign.campaignId]);

    const isDraftCampaign = campaign.deliveryStatusId === 'DRAFT';

    // const isCampaignChangeRequest = useMemo((): boolean => {
    //     if (campaignId && campaignChangeRequestId) {
    //         return true;
    //     }
    //     return false;
    // }, [campaignChangeRequestId]);

    const campaignBidStrategyLabel = useMemo((): string => {
        if (campaign.bidStrategy) {
            switch (campaign.bidStrategy) {
                case 'SPEND_BUDGET_IN_FULL':
                    return 'Spend Budget in Full';

                case 'PRIORITIZE_KPI_TARGET':
                    return 'Prioritize KPI Target';
            }

            return campaign.bidStrategy;
        }

        return '';
    }, [campaign.bidStrategy]);

    const campaignBudgetManagementStrategyLabel = useMemo((): string => {
        if (campaign.automateBudgetAllocation === true) {
            return 'Automate Budget Allocation';
        }

        if (campaign.automateBudgetAllocation === false) {
            return 'Manage Budget Manually';
        }

        return '';
    }, [campaign.automateBudgetAllocation]);

    const isInvalidField = (field: string): boolean => {
        switch (field) {
            case 'adGroups.*.adGroupAudienceSources':
                if (!campaign?.adGroups?.length) {
                    return true;
                }

                if (!campaign.adGroups[0]?.adGroupAudienceSources?.length) {
                    return true;
                }
                break;

            case 'adGroups.*.adGroupLocations':
                if (!campaign?.adGroups?.length) {
                    return true;
                }

                if (!campaign.adGroups[0]?.adGroupLocations?.length) {
                    return true;
                }
                break;

            case 'adGroups.*.adGroupInventorySources':
                if (!campaign?.adGroups?.length) {
                    return true;
                }

                if (!campaign.adGroups[0]?.adGroupInventorySources?.length) {
                    return true;
                }
                break;

            case 'adGroups.*.adGroupProductCategories':
                if (!campaign?.adGroups?.length) {
                    return true;
                }

                if (!campaign.adGroups[0]?.adGroupProductCategories?.length) {
                    return true;
                }
                break;

            case 'adTagUrl':
                return campaign.campaignType === 'AdTag' && !campaign.adTagUrl;

            case 'bidStrategy':
                return !campaign.bidStrategy;

            case 'budget':
                return !campaign.budget;

            case 'budgetCapType':
                return !campaign.budgetCapType;

            case 'budgetCapRecurrenceTimePeriod':
                return campaign.budgetCapType === 'CUSTOM' && !campaign.budgetCapRecurrenceTimePeriod;

            case 'budgetCapAmount':
                return campaign.budgetCapType === 'CUSTOM' && !campaign.budgetCapAmount;

            case 'campaignType':
                return !campaign.campaignType;

            case 'dealerId':
                return !campaign.dealerId;

            case 'endDate':
                return !campaign.endDate;

            case 'files':
                return campaign.campaignType === 'Static' && !files.length;

            case 'frequencyType':
                return !campaign.frequencyType;

            case 'frequencyMaxImpressions':
                return campaign.frequencyType === 'CUSTOM' && !campaign.frequencyMaxImpressions;

            case 'frequencyTimeUnitCount':
                return campaign.frequencyType === 'CUSTOM' && !campaign.frequencyTimeUnitCount;

            case 'frequencyTimeUnit':
                return campaign.frequencyType === 'CUSTOM' && !campaign.frequencyTimeUnit;

            case 'goal':
                return !campaign.goal;

            case 'kpi':
                return !campaign.kpi;

            case 'mediaType':
                return campaign.mediaType === '';

            case 'startDate':
                return !campaign.startDate;

            case 'strategyType':
                return campaign.strategyType === '';

            case 'targetKpi':
                if (campaign.bidStrategy === 'PRIORITIZE_KPI_TARGET') {
                    return !['Awareness'].includes(campaign.goal as string) && !campaign.targetKpi;
                } else {
                    return campaign.targetKpiDisabled === false && !campaign.targetKpi;
                }
                break;

            case 'targetKpiDisabled':
                if (campaign.bidStrategy === 'PRIORITIZE_KPI_TARGET') {
                    return false;
                } else {
                    return campaign.targetKpiDisabled === undefined;
                }
                break;

            case 'zipcodes':
                return !campaign.zipcodes;
        }

        return false;
    };

    const isValidField = (field: string): boolean => {
        return isInvalidField(field) === false;
    };

    const hasError = (field: string): boolean => {
        if (hasFormError === false) {
            return false;
        }

        return isInvalidField(field);
    };

    const isValidStep = (step: number): boolean => {
        let fields: string[] = [];

        switch (step) {
            case STEP_CAMPAIGN_SETUP:
                fields.push(...['dealerId', 'mediaType', 'strategyType', 'startDate', 'endDate', 'budget']);

                if (isNewCampaign || isDraftCampaign) {
                    fields.push(
                        ...[
                            'adGroups.*.adGroupProductCategories',
                            'adGroups.*.adGroupInventorySources',
                            'budgetCapRecurrenceTimePeriod',
                            'budgetCapAmount',
                            'goal',
                            'kpi',
                            'targetKpi',
                            'targetKpiDisabled',
                            'bidStrategy',
                        ]
                    );
                }
                break;

            case STEP_CAMPAIGN_GEOGRAPHY:
                fields.push(...['adGroups.*.adGroupAudienceSources', 'adGroups.*.adGroupLocations', 'zipcodes']);
                break;

            case STEP_CAMPAIGN_CREATIVE:
                fields.push(...['campaignType']);

                if (campaign.campaignType === 'Static') {
                    fields.push(...['files']);
                }

                if (campaign.campaignType === 'AdTag') {
                    fields.push(...['adTagUrl']);
                }
                break;
        }

        if (fields.length > 0) {
            for (const field in fields) {
                if (isInvalidField(fields[field])) {
                    return false;
                }
            }
        }

        return true;
    };

    const stepper = useStepper({
        canNextStep: (step: number): boolean => {
            return isValidStep(step);
        },
        canPreviousStep: (step: number): boolean => {
            if (step === 0 || saveInProgress) {
                return false;
            }
            return true;
        },
        defaultActiveStep: 0,
        jumpable: true,
        maxStep: 3,
        minStep: 0,
        onBeforeNextStep: async (step: number): Promise<void> => {
            if (!stepper.canNextStep()) {
                setHasFormError(true);
                stepper.setErrorStep(step);
            } else {
                setHasFormError(false);
                stepper.setErrorStep(-1);

                if (!campaign.id || campaign.deliveryStatusId === 'DRAFT') {
                    try {
                        const response = await updateDraftCampaign();
                        const result = await ApiService.getCampaign(response.data.id);

                        if (!campaign.id) {
                            window.history.pushState('', '[cognition]', `/campaign/v2/${result.data.id}`);
                        }

                        setCampaign({
                            ...result.data,

                            // Mimic CampaignChangeRequest
                            status: null,
                            statusDate: null,
                        });
                    } catch (error) {}
                }
            }
        },
        onBeforePreviousStep: async (step: number): Promise<void> => {
            if (!campaign.id || campaign.deliveryStatusId === 'DRAFT') {
                try {
                    const response = await updateDraftCampaign();
                    const result = await ApiService.getCampaign(response.data.id);

                    if (!campaign.id) {
                        window.history.pushState('', '[cognition]', `/campaign/v2/${result.data.id}`);
                    }

                    setCampaign({
                        ...result.data,

                        // Mimic CampaignChangeRequest
                        status: null,
                        statusDate: null,
                    });
                } catch (error) {}
            }
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (!validateCampaign()) {
            showInfoMessage('error', 'Please fill out all required fields.');
            return;
        }

        updateCampaign().then((response) => {
            setWasRecentlySubmitted(true);
        });
    };

    const deleteDraftCampaign = (): Promise<any> => {
        if (campaign?.campaignId) {
            return ApiService.deleteCampaign(campaign.campaignId).catch(() => {});
        }

        return Promise.resolve({});
    };

    const updateDraftCampaign = (): Promise<any> => {
        return ApiService.updateCampaign(
            toCampaignUpdatePayload({
                ...campaign,
                campaignName: isNewCampaign ? getCampaignName() : campaign?.campaignName,
                deliveryStatusId: 'DRAFT',
                deliveryStatus:
                    campaign?.deliveryStatus?.dspStatusId === 'DRAFT'
                        ? campaign.deliveryStatus
                        : { dspStatusId: 'DRAFT' },
                activationStatusId: 'INACTIVE',
                startDate: campaign.startDate ? Utils.toStartOfDayUTC(campaign.startDate) : null,
                endDate: campaign.endDate ? Utils.toEndOfDayUTC(campaign.endDate) : null,
            }),
            files
        ).catch(() => {});
    };

    const updateCampaign = (): Promise<any> => {
        if (userContext.isAgency()) {
            campaign.hasUpdates = true;
        }
        if (!campaign.impressionBased) {
            campaign.impressionGoal = null;
        }

        if (campaign.campaignId && campaign.deliveryStatusId !== 'DRAFT') {
            setSaveInProgress(true);
            setWasRecentlyUpdated(true);

            return ApiService.updateCampaignChangeRequest(
                toCampaignUpdatePayload({
                    ...campaign,
                    startDate: campaign.startDate ? Utils.toStartOfDayUTC(campaign.startDate) : null,
                    endDate: campaign.endDate ? Utils.toEndOfDayUTC(campaign.endDate) : null,
                    status: campaign.status ? campaign.status : 'Pending',
                }),
                files
            );
        } else {
            const payload: CampaignUpdatePayload = toCampaignUpdatePayload({
                ...campaign,
                deliveryStatusId:
                    campaign.deliveryStatusId === 'DRAFT' ? 'PENDING_COGNITION' : campaign.deliveryStatusId,
                deliveryStatus:
                    campaign.deliveryStatusId === 'DRAFT'
                        ? { dspStatusId: 'PENDING_COGNITION' }
                        : campaign.deliveryStatus,
                startDate: campaign.startDate ? Utils.toStartOfDayUTC(campaign.startDate) : null,
                endDate: campaign.endDate ? Utils.toEndOfDayUTC(campaign.endDate) : null,
            });

            setWasRecentlyCreated(true);

            return ApiService.updateCampaign(payload, files)
                .then(() => {
                    if (!campaign.id) {
                        // showInfoMessage('success', 'Campaign has been created.');
                        // setTimeout(() => {
                        //     navigate('/');
                        // }, 1000);
                    } else {
                        // showInfoMessage('success', 'Campaign has been updated.');
                        setFiles([]);
                    }

                    if (campaign.id && campaign.gatewayAmazonDSPIsActive !== 2) {
                        setTimeout(() => {
                            navigate(`/campaignManager/${campaign.id}`, {
                                state: { wasRecentlyCreated: true },
                            });
                        }, 1000);
                    }
                })
                .catch(() => {});
        }
    };

    const isValidCampaign = (): boolean => {
        if (!isValidStep(STEP_CAMPAIGN_SETUP)) {
            return false;
        }

        if (!isValidStep(STEP_CAMPAIGN_GEOGRAPHY)) {
            return false;
        }

        if (!isValidStep(STEP_CAMPAIGN_CREATIVE)) {
            return false;
        }

        return true;
    };

    const validateCampaign = (): boolean => {
        setHasFormError(false);

        if (!isValidCampaign()) {
            setHasFormError(true);
            return false;
        }
        return true;
    };

    const populateImmediateDates = (days: number = 1) => {
        let endDate = Utils.getMonthEnd(0);
        if (campaign.endDate && campaign.endDate > endDate) {
            endDate = campaign.endDate;
        }
        let startDate = new Date();
        startDate.setDate(startDate.getDate() + days);
        setCampaign((prev) => ({
            ...prev,
            startDate: startDate,
            endDate: endDate,
        }));
    };

    const fetchCampaign = useCallback(() => {
        if (campaignId && campaignChangeRequestId) {
            // setDuplicateCampaigns([]);
            ApiService.getCampaignChangeRequest(campaignId, campaignChangeRequestId)
                .then((response) => {
                    let campaignChangeRequest = response.data;
                    campaignChangeRequest.changeRequestId = campaign.id;
                    setCampaign(campaignChangeRequest);

                    if (campaignChangeRequest.status === 'Pending') {
                        populateImmediateDates(0);
                    }

                    const isComplete = campaignChangeRequest.status === 'Complete';
                    setIsCompletedCampaign(isComplete);
                    stepper.setActiveStep(isComplete ? STEP_CAMPAIGN_REVIEW : STEP_CAMPAIGN_SETUP);
                    // calculateAvailableImpressions();
                    // setLoaded(true);
                })
                .catch(() => {});
        } else if (campaignId) {
            ApiService.getCampaign(campaignId)
                .then((response) => {
                    setCampaign({
                        ...response.data,
                        status: null,
                        statusDate: null,
                    });
                    stepper.setActiveStep(STEP_CAMPAIGN_SETUP);
                })
                .catch(() => {});
        } else {
            // setLoaded(true);
        }
        // ApiService.getForecastingParameters()
        //     .then((result) => {
        //         setForecastingParameters(result.data);
        //     })
        //     .catch(() => {});
        // }, [calculateAvailableImpressions, campaignId, campaignChangeRequestId, isCampaignChangeRequest]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId, campaignChangeRequestId]);

    return {
        // Audience Sources
        $audienceSources,
        // Inventory Sources
        $inventorySources,
        // Geo Locations
        $locations,
        // Product Categories
        $productCategories,
        // Codes
        $bidStrategyCodes,
        $campaignTypeCodes,
        $goalTypeCodes,
        $kpiTypeCodes,
        $mediaTypeCodes,
        $strategyTypeCodes,

        //
        $loader,
        $campaignGeographyTargeting,
        $campaignZipCodes,
        $campaignDefaultSettings,

        campaign,
        campaignBidStrategyLabel,
        campaignBudgetManagementStrategyLabel,
        campaignId,
        campaignChangeRequestId,
        dealer,
        deleteDraftCampaign,
        fetchCampaign,
        files,
        generateCampaignName,
        getCampaignName,
        handleSubmit,
        hasError,
        hasFormError,
        ignoreDuplicates,
        isCompletedCampaign,
        isDraftCampaign,
        isInvalidField,
        isNewCampaign,
        isValidCampaign,
        isValidField,
        isValidStep,
        resetCampaignWizard,
        saveInProgress,
        setCampaign,
        setFiles,
        setHasFormError,
        setIgnoreDuplicates,
        setIsCompletedCampaign,
        setSaveInProgress,
        setWasRecentlyCreated,
        setWasRecentlyUpdated,
        setWasRecentlyLoaded,
        setWasRecentlySubmitted,
        stepper,
        updateCampaign,
        updateDraftCampaign,
        validateCampaign,
        wasRecentlyCreated,
        wasRecentlyUpdated,
        wasRecentlyLoaded,
        wasRecentlySubmitted,
    };
}
