import { ChangeEvent, MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { FormControl, Grid } from '@mui/material';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDTextField, CDTextFieldProps } from '../../../../components/CDTextField';
import { Location } from '../../../../types/Location';
import { ZipCode } from '../../../campaignManagerV2/campaignEdit/MapComponent';
import { parseZipCodes } from '../../../../components/Location/ZipCodeList';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import CDButton from '../../../../components/CDButton';
import Column from '../../../../components/Column';
import ZipCodeList from '../../../../components/Location/ZipCodeList';

export const toCampaignZipcodes = (zipCodes: ZipCode[]): string => {
    return zipCodes.map((zipCode: ZipCode) => zipCode.code).join(',');
};

const ZipCodeRawFieldGroup = (
    props: {
        onChange: (zipCodes: ZipCode[]) => void;
    } & Omit<CDTextFieldProps, 'onChange'>
) => {
    const { onChange, ...textFieldProps } = props;
    const [rawZipCodes, setRawZipCodes] = useState<string>('');
    const [items, setItems] = useState<ZipCode[]>([]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRawZipCodes(event.target.value);
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (items) {
            props.onChange(items);
        }

        setRawZipCodes('');
        // setItems([]);
    };

    useEffect(() => {
        setItems(parseZipCodes(rawZipCodes));
    }, [rawZipCodes]);

    return (
        <Column>
            <FormControl fullWidth size="small">
                <CDTextField
                    label="ZIP Codes"
                    value={rawZipCodes}
                    onChange={handleChange}
                    shrinkLabel
                    multiline
                    rows={3}
                    placeholder="Enter a comma-separated list of ZIP codes or enter one per line"
                    {...textFieldProps}
                />
            </FormControl>

            <CDButton variant="contained" color="primary" disabled={rawZipCodes.length === 0} onClick={handleClick}>
                Add Zip Codes
            </CDButton>
        </Column>
    );
};

type CampaignLocationZipCodeListProps = {
    items: ZipCode[];
    onChangeMany?: (items: ZipCode[]) => void;
    onAttachMany?: (items: ZipCode[]) => void;
    onDetachMany?: (items: ZipCode[]) => void;
} & CampaignWizardStepProps;

export default function CampaignLocationZipCodeList(props: CampaignLocationZipCodeListProps) {
    const { items, onAttachMany, onDetachMany, onChangeMany } = props;

    const { $locations } = useCampaignWizardContext();
    const { locations = [] } = $locations || {};

    const handleChangeMany = useCallback(
        (items: ZipCode[]) => {
            if (onChangeMany) {
                onChangeMany(items);
            }
        },
        [onChangeMany]
    );

    const handleAttachMany = useCallback(
        (items: ZipCode[]) => {
            if (onAttachMany) {
                onAttachMany(items);
            }
        },
        [onAttachMany]
    );

    const handleDetachMany = useCallback(
        (items: ZipCode[]) => {
            if (onDetachMany) {
                onDetachMany(items);
            }
        },
        [onDetachMany]
    );

    const getZipCodeLabel = (item: ZipCode): ReactNode => {
        const location = locations.find((o: Location) => o.zipCode === item.code);
        return location ? location.name : item.code;
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <Column sx={{ px: 2, pt: 3, pb: 3 }}>
                    <ZipCodeRawFieldGroup
                        onChange={(zipCodes: ZipCode[]) => {
                            zipCodes = zipCodes.filter((zipCode: ZipCode) => {
                                return !items.find((o: ZipCode) => o.code === zipCode.code);
                            });

                            if (zipCodes.length > 0) {
                                zipCodes.forEach((zipCode: ZipCode) => {
                                    items.push(zipCode);
                                });

                                handleAttachMany([...zipCodes]);
                                handleChangeMany([...items]);
                            }
                        }}
                    />
                </Column>
            </Grid>

            <Grid item xs={6} sx={{ borderLeft: 1, borderColor: 'divider' }}>
                <Column>
                    <ZipCodeList
                        items={items}
                        getItemLabel={getZipCodeLabel}
                        onDetach={(zipCode: ZipCode) => {
                            const index = items.indexOf(zipCode);

                            if (index > -1) {
                                items.splice(index, 1);
                                handleDetachMany([zipCode]);
                                handleChangeMany([...items]);
                            }
                        }}
                        sx={{
                            bgcolor: 'background.paper',
                            height: 400,
                            overflow: 'auto',
                            position: 'relative',
                        }}
                    />
                </Column>
            </Grid>
        </Grid>
    );
}
