import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, RadioGroup } from '@mui/material';

import { Code } from '../../../types/Code';
import { RadioCard as BaseRadioCard, RadioCardProps, RadioCardGroupProps } from '../../../components/RadioCard';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';

const RadioCard = styled((props: RadioCardProps) => (
    <BaseRadioCard
        CardProps={{
            sx: {
                '& .MuiCardContent-root': {
                    height: 122,
                },
            },
        }}
        {...props}
    />
))(({ theme }) => ({}));

type CampaignCreativeTypeRadioGroupProps = {
    filter?(codes: Code[]): Code[];
} & RadioCardGroupProps;

export default function CampaignCreativeTypeRadioGroup(props: CampaignCreativeTypeRadioGroupProps) {
    const { disabled, filter, ...radioGroupProps } = props;
    const { $campaignTypeCodes } = useCampaignWizardContext();
    const { codes = [], defaultCode = null, fetchCodes = () => {} } = $campaignTypeCodes || {};

    const [filteredCodes, setFilteredCodes] = useState<Code[]>([]);

    const defaultValue = useMemo(() => {
        return defaultCode ? defaultCode.code : '';
    }, [defaultCode]);

    useEffect(() => {
        fetchCodes();
    }, [fetchCodes]);

    useEffect(() => {
        if (codes && codes.length > 0) {
            setFilteredCodes(filter ? filter(codes) : codes);
        } else {
            setFilteredCodes([]);
        }

        // @TODO Mocked data
        const mockedCodes: Code[] = [
            {
                id: 0,
                codeGroup: 'CampaignType',
                code: 'DCO',
                description: 'Dynamic Creative',
                defaultOption: true,
                sortOrder: 0,
            },
            {
                id: 0,
                codeGroup: 'CampaignType',
                code: 'Static',
                description: 'Static Creative',
                defaultOption: false,
                sortOrder: 1,
            },
            {
                id: 0,
                codeGroup: 'CampaignType',
                code: 'AdTag',
                description: 'Creative Ad Tag',
                defaultOption: false,
                sortOrder: 2,
            },
        ];

        setFilteredCodes(filter ? filter(mockedCodes) : mockedCodes);
    }, [codes, filter]);

    const getCodeLabel = (code: Code): string => {
        switch (code.code) {
            case 'DCO':
                return 'Dynamic Creative';

            case 'Static':
                return 'Static Creative';

            case 'AdTag': // @TODO
                return 'Creative Ad Tag';
        }

        return code.description;
    };

    const getCodeDescription = (code: Code): string => {
        switch (code.code) {
            case 'DCO':
                return "Automatically generate ads based on the selected Campaign Strategy using Cognition's Creative Platform";

            case 'Static':
                return 'Upload your own creative asset. Audio, Image and Video files are supported';

            case 'AdTag':
                return 'Provide your own Creative Ad Tag URL';
        }

        return code.description;
    };

    return (
        <RadioGroup defaultValue={defaultValue} {...radioGroupProps} value={props.value}>
            {filteredCodes && (
                <Grid container spacing={3}>
                    {filteredCodes.map((code: Code) => {
                        return (
                            <Grid key={code.code} item xs={4}>
                                <RadioCard
                                    required={true}
                                    disabled={disabled}
                                    value={code.code}
                                    description={getCodeDescription(code)}
                                    label={getCodeLabel(code)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </RadioGroup>
    );
}
