import { ChangeEvent, ReactNode, useRef } from 'react';
import { styled } from '@mui/material';
import { Button, Typography } from '@mui/material';
import { FileUploadOutlined as FileUploadOutlinedIcon } from '@mui/icons-material';

import Column from './Column';
import Row from './Row';

const Container = styled(Column)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius / 2,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.grey['300'],
    height: 180,
    position: 'relative',
}));

const Input = styled('input')({
    cursor: 'pointer',
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
});

type MediaFileUploadProps = {
    children?: ReactNode;
    inputProps?: any;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function MediaFileUpload(props: MediaFileUploadProps) {
    const { children, inputProps, onChange } = props;

    const $input = useRef<HTMLInputElement>(null);

    return (
        <Container gap={1} alignItems="center" justifyContent="center">
            <FileUploadOutlinedIcon color="primary" />

            <Input ref={$input} {...inputProps} onChange={onChange} type="file" />

            <Row gap={0} alignItems="center" justifyContent="center">
                <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    size="small"
                    sx={{ fontSize: 14 }}
                    onClick={() => {
                        if ($input.current) {
                            $input.current.click();
                        }
                    }}
                >
                    Click to upload
                </Button>

                <Typography variant="subtitle2" color="text.primary">
                    or drag and drop
                </Typography>
            </Row>

            {children ? (
                children
            ) : (
                <Typography variant="body2" color="text.secondary">
                    PNG, JPEG or JPG
                </Typography>
            )}
        </Container>
    );
}
