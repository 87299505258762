import { styled } from '@mui/material';
import { Avatar as BaseAvatar, Chip as BaseChip, ChipProps } from '@mui/material';

const Avatar = styled(BaseAvatar)(({ theme }) => ({
    '&': {
        backgroundColor: theme.palette.action.selected,
        minWidth: 24,
        height: 24,
    },
    '&.MuiChip-deleteIcon': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.caption.fontSize,
    },
}));

const Chip = styled(BaseChip)(({ theme }) => ({
    '& .MuiChip-deleteIcon': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.caption.fontSize,
    },
}));

export type CountChipProps = {
    label: React.ReactNode;
    count?: number;
    hideCount?: boolean;
    hideZeroCount?: boolean;
} & ChipProps;

export default function CountChip(props: CountChipProps) {
    const { label, count, hideCount, hideZeroCount, ...chipProps } = props;

    return (
        <Chip
            {...chipProps}
            label={label}
            deleteIcon={hideCount || hideZeroCount ? <></> : <Avatar>{count}</Avatar>}
            onDelete={() => {}}
        />
    );
}
