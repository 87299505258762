import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Campaign } from '../../../types/Campaign';
import CampaignModifyConfirmButton from './CampaignModifyConfirmButton';
import CampaignSummary from './CampaignSummary';
import Row from '../../../components/Row';

type CampaignSummaryDialogProps = {
    campaign: Campaign;
    open: boolean;
    onClose(): void;
};

export default function CampaignSummaryDialog(props: CampaignSummaryDialogProps) {
    const { campaign } = props;

    const handleCampaignConfirm = () => {
        if (campaign && campaign.id) {
            window.open(`/campaign/v2/${campaign.id}`, '_blank');
        }
    };

    return (
        <Dialog fullWidth={true} maxWidth="md" open={props.open} onClose={() => props.onClose()}>
            <DialogTitle>Campaign Summary</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => props.onClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                <CampaignSummary campaign={campaign} />
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Row gap={1} justifyContent="flex-end" alignItems="center">
                    <Button variant="outlined" onClick={() => props.onClose()}>
                        Cancel
                    </Button>

                    <CampaignModifyConfirmButton campaign={campaign} onConfirm={handleCampaignConfirm} />
                </Row>
            </DialogActions>
        </Dialog>
    );
}
