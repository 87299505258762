import { useEffect, useState } from 'react';
import { Box, List, ListItemText } from '@mui/material';
import { ListItemButton } from './CampaignWizardStyles';

type CampaignWizardSetupStepNavProps = {
    // step: number;
    onStep?: (step: number) => void;
};

export default function CampaignWizardSetupStepNav(props: CampaignWizardSetupStepNavProps) {
    const { onStep } = props;
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        if (onStep) {
            onStep(selectedIndex);
        }
    }, [onStep, selectedIndex]);

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List component="nav" aria-label="secondary campaign setup sections">
                <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                    <ListItemText primary="Advertiser Info" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                    <ListItemText primary="Media Type & Strategy" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 5} onClick={(event) => handleListItemClick(event, 5)}>
                    <ListItemText primary="Goal & Optimization" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
                    <ListItemText primary="Inventory" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
                    <ListItemText primary="Schedule & Budget" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === 6} onClick={(event) => handleListItemClick(event, 6)}>
                    <ListItemText primary="Exposure Frequency" />
                </ListItemButton>
            </List>
        </Box>
    );
}
