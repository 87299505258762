import { MouseEvent, useState } from 'react';
import { Box, ToggleButtonGroup } from '@mui/material';

import { Campaign } from '../../../../types/Campaign';
import { ToggleButton } from './CampaignWizardStyles';
import CampaignReviewCampaignCard from './CampaignReviewCampaignCard';
import CampaignReviewDetailedSummary from './CampaignReviewDetailedSummary';
import CampaignReviewQuickSummary from './CampaignReviewQuickSummary';
import Column from '../../../../components/Column';

export type CampaignWizardReviewStepProps = {
    campaign: Campaign;
    onChange(value: Partial<Campaign>): void;
};

export default function CampaignWizardReviewStep(props: CampaignWizardReviewStepProps) {
    const { campaign, onChange } = props;

    const [view, setView] = useState<string>('QUICK_REVIEW');

    const handleViewChange = (event: MouseEvent<HTMLElement>, newView: string): void => {
        if (newView) {
            setView(newView);
        }
    };

    return (
        <Column gap={3}>
            <Box>
                <CampaignReviewCampaignCard campaign={campaign} onChange={onChange} />
            </Box>

            <Box>
                <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleViewChange} aria-label="View">
                    <ToggleButton value="QUICK_REVIEW">Quick Review</ToggleButton>
                    <ToggleButton value="DETAILED_REVIEW">Detailed Review</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box>
                {view === 'QUICK_REVIEW' && <CampaignReviewQuickSummary campaign={campaign} />}
                {view === 'DETAILED_REVIEW' && <CampaignReviewDetailedSummary campaign={campaign} />}
            </Box>
        </Column>
    );
}
