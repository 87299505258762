import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel as BaseFormControlLabel,
    Drawer,
    DrawerProps,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { Card, CardHeader } from '../../pages/campaign/partials/CampaignWizard/CampaignWizardStyles';
import { ClearOutlined as ClearOutlinedIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { AudienceSource, AudienceSourceCriteria } from '../../types/AudienceSource';
import { AudienceSourceCategory } from '../../types/AudienceSourceCategory';
import { StickyBottomAppBar } from '../../components/AppBar';
import { useAudienceSourceCategories } from '../../hooks/useAudienceSourceCategories';
import CDButton, { CDDefaultButton } from '../CDButton';
import Column from '../Column';
import AudienceDataGridCard from './AudienceSourceDataGridCard';
import Row from '../Row';

const FormControlLabel = styled(BaseFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiCheckbox-root': {
        marginRight: theme.spacing(2),
    },
}));

export type AudienceSourceDrawerProps = {
    mediaType?: string;

    // Use this hook to provide actual data
    // '../../hooks/useAudienceSources'
    selections: AudienceSource[];
    fetchSelections?: () => void;
    hydrateSelections?: (selections: AudienceSource[]) => AudienceSource[];

    items?: AudienceSource[];
    onChange?: (items: AudienceSource[]) => void;
    onClose(items: AudienceSource[], reason: 'cancel' | 'save'): void;
} & Omit<DrawerProps, 'onChange' | 'onClose'>;

export default function AudienceSourceDrawer(props: AudienceSourceDrawerProps) {
    const { mediaType, onChange, onClose, items, selections, fetchSelections, hydrateSelections, ...drawerProps } =
        props;

    // Dictionary of Audience Source Categories
    // resolved by using the "selections" property
    // of list of Audience Sources.
    const $audienceSourceCategories = useAudienceSourceCategories();

    const [searchCriteria, setSearchCriteria] = useState<AudienceSourceCriteria>({
        mediaType: '',
        searchText: '',
        categories: [],
    });
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<AudienceSource[]>([]);

    const handleSelectionChanged = (items: AudienceSource[]) => {
        setIsDirty(true);
        setSelectedItems(items);
    };

    function changeSearchCriteria(searchCriteria: AudienceSourceCriteria) {
        setSearchCriteria({ ...searchCriteria });
    }

    function handleAudienceSourceCategoryChange(event: any): void {
        if (event?.target?.value) {
            const [category, subCategory] = event.target.value.split('|');

            if (event.target.checked) {
                if (!searchCriteria.categories.includes(category)) {
                    searchCriteria.categories.push(category);
                }

                if (!searchCriteria.categories.includes(subCategory)) {
                    // searchCriteria.categories.push(event.target.value);
                }

                if (!searchCriteria.categories.includes(event.target.value)) {
                    searchCriteria.categories.push(event.target.value);
                }

                if (subCategory) {
                    setSelectedItems((prevSelectedItems: AudienceSource[]): AudienceSource[] => {
                        return [
                            ...prevSelectedItems.filter((item: AudienceSource) => {
                                if (item.category === category) {
                                    return item.subCategory !== subCategory;
                                }

                                return true;
                            }),
                            ...selections.filter((item: AudienceSource) => {
                                if (item.category === category) {
                                    return item.subCategory === subCategory;
                                }

                                return false;
                            }),
                        ];
                    });
                }

                changeSearchCriteria(searchCriteria);
            } else {
                if (category && !subCategory) {
                    setSelectedItems((prevSelectedItems: AudienceSource[]): AudienceSource[] => {
                        return [
                            ...prevSelectedItems.filter((item: AudienceSource) => {
                                return item.category !== category;
                            }),
                        ];
                    });
                }

                if (subCategory) {
                    setSelectedItems((prevSelectedItems: AudienceSource[]): AudienceSource[] => {
                        return [
                            ...prevSelectedItems.filter((item: AudienceSource) => {
                                if (item.category === category) {
                                    return item.subCategory !== subCategory;
                                }

                                return true;
                            }),
                        ];
                    });
                }

                changeSearchCriteria({
                    ...searchCriteria,
                    categories: searchCriteria.categories.filter((s: string) => s !== event.target.value),
                });
            }

            //
            setIsDirty(true);
        }
    }

    useEffect(() => {
        $audienceSourceCategories.setAudienceSourceCategories(
            selections ? $audienceSourceCategories.identifyAudienceSourceCategories(selections) : []
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);

    useEffect(() => {
        if (fetchSelections) {
            fetchSelections();
        }
    }, [fetchSelections]);

    useEffect(() => {
        setSelectedItems(items ?? []);
    }, [items]);

    useEffect(() => {
        changeSearchCriteria({
            ...searchCriteria,
            mediaType: mediaType,
        });
    }, [mediaType]);

    useEffect(() => {
        // Make sure we have the list of audience sources
        // so we will have the audience source names
        // to display for selected audience sources.

        if (hydrateSelections) {
            if (selections.length > 0 && items) {
                const hydratedSelectedItems = hydrateSelections(items);

                if (onChange) {
                    onChange(hydratedSelectedItems);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);

    return (
        <Drawer
            anchor="right"
            onClose={() => onClose([], 'cancel')}
            {...drawerProps}
            sx={{
                width: 4 / 5,
                flexShrink: 0,
                zIndex: (theme) => theme.zIndex.drawer + 1,

                '& .MuiDrawer-paper': {
                    width: 4 / 5,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Column
                justifyContent="space-between"
                sx={{
                    minHeight: '480px',
                    height: '100%',
                }}
            >
                <Box>
                    <Box
                        component="header"
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            paddingX: 3,
                            paddingY: 2,
                        }}
                    >
                        <Row justifyContent="space-between" alignItems="center" spacing={2}>
                            <Stack spacing={1}>
                                <Typography variant="h6">Select Audience Segments</Typography>
                            </Stack>

                            <IconButton onClick={() => onClose([], 'cancel')} edge="end">
                                <ClearOutlinedIcon fontSize="medium" color="action" />
                            </IconButton>
                        </Row>
                    </Box>

                    <Box sx={{ padding: 3 }}>
                        <Row gap={3}>
                            <Card variant="outlined" sx={{ width: 4 / 12, minHeight: '65vh' }}>
                                <CardHeader
                                    title={
                                        <Row spacing={0.5} alignItems="center">
                                            <Typography variant="subtitle2" color="text.primary">
                                                Select Audience Categories
                                            </Typography>
                                        </Row>
                                    }
                                />
                                <Box>
                                    <FormGroup onChange={handleAudienceSourceCategoryChange}>
                                        {$audienceSourceCategories.audienceSourceCategories.map(
                                            (category: AudienceSourceCategory, index: number) => {
                                                return (
                                                    <Box
                                                        key={category.id}
                                                        sx={{
                                                            borderBottomWidth: 1,
                                                            borderBottomColor: 'divider',
                                                            borderBottomStyle: 'solid',
                                                        }}
                                                    >
                                                        <Accordion disableGutters square elevation={0}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                sx={{
                                                                    '& .MuiAccordionSummary-content': {
                                                                        my: 0,
                                                                    },
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={searchCriteria.categories.includes(
                                                                                category.code
                                                                            )}
                                                                        />
                                                                    }
                                                                    label={category.name}
                                                                    value={category.code}
                                                                />
                                                            </AccordionSummary>
                                                            <AccordionDetails
                                                                sx={{
                                                                    height: 250,
                                                                    overflowY: 'auto',
                                                                }}
                                                            >
                                                                {category.children.map(
                                                                    (subCategory: AudienceSourceCategory) => {
                                                                        return (
                                                                            <Box key={subCategory.code} sx={{ ml: 5 }}>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={searchCriteria.categories.includes(
                                                                                                subCategory.code
                                                                                            )}
                                                                                        />
                                                                                    }
                                                                                    label={subCategory.name}
                                                                                    value={subCategory.code}
                                                                                    sx={{ mr: 0 }}
                                                                                />
                                                                            </Box>
                                                                        );
                                                                    }
                                                                )}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Box>
                                                );
                                            }
                                        )}
                                    </FormGroup>
                                </Box>
                            </Card>

                            <AudienceDataGridCard
                                rows={selections}
                                selectedRows={selectedItems}
                                onSelectionsChanged={handleSelectionChanged}
                                searchCriteria={searchCriteria}
                                changeSearchCriteria={changeSearchCriteria}
                            />
                        </Row>
                    </Box>
                </Box>

                <StickyBottomAppBar>
                    <Row justifyContent="flex-end" gap={1}>
                        <CDButton onClick={() => onClose([], 'cancel')}>Cancel</CDButton>
                        <CDDefaultButton
                            onClick={() => {
                                if (isDirty) {
                                    onClose(selectedItems, 'save');
                                } else {
                                    onClose([], 'cancel');
                                }

                                setIsDirty(false);
                            }}
                            color="primary"
                        >
                            Save
                        </CDDefaultButton>
                    </Row>
                </StickyBottomAppBar>
            </Column>
        </Drawer>
    );
}
