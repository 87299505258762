import { useState } from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Campaign } from '../../../../types/Campaign';
import ConfirmDialog, { ConfirmDialogProps } from '../../../../components/ConfirmDialog';

type CampaignWizardSaveConfirmButtonProps = {
    campaign: Campaign;
    onConfirm?: (campaign: Campaign) => void;
    onCancel?: (campaign: Campaign) => void;
    onClose?: (campaign: Campaign) => void;
};

export default function CampaignWizardSaveConfirmButton(props: CampaignWizardSaveConfirmButtonProps) {
    const { campaign } = props;

    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const handleShowConfirmDialog = () => {
        showConfirm();
    };

    const handleConfirm = () => {
        hideConfirm();

        if (props.onConfirm) {
            props.onConfirm(campaign);
        }
    };

    const handleCancel = () => {
        hideConfirm();

        if (props.onCancel) {
            props.onCancel(campaign);
        }
    };

    const handleClose = (): void => {
        hideConfirm();
    };

    const showConfirm = () => {
        if (campaign.deliveryStatusId === 'DRAFT') {
            setConfirmDialogProps({
                title: 'Save Campaign?',
                text: 'Do you want to save campaign as draft before exit?',
                onOk: () => handleConfirm(),
                okText: 'Save Draft',
                okColor: 'primary',
                onCancel: () => handleCancel(),
                cancelText: 'Delete & Exit',
                cancelColor: 'primary',
                cancelIcon: null,
                onClose: () => handleClose(),
            });
        } else {
            setConfirmDialogProps({
                title: 'Save Campaign?',
                text: 'Do you want to save campaign before exit?',
                onOk: () => handleConfirm(),
                okText: 'Save & Update',
                okColor: 'primary',
                onCancel: () => handleCancel(),
                cancelText: "Don't Save & Exit",
                cancelColor: 'primary',
                cancelIcon: null,
                onClose: () => handleClose(),
            });
        }
    };

    const hideConfirm = () => {
        setConfirmDialogProps(null);
    };

    return (
        <>
            <IconButton aria-label="close" size="medium" onClick={handleShowConfirmDialog}>
                <CloseIcon fontSize="inherit" />
            </IconButton>

            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        cancelText={confirmDialogProps.cancelText}
                        cancelIcon={confirmDialogProps.cancelIcon}
                        cancelColor={confirmDialogProps.cancelColor}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        okColor={confirmDialogProps.okColor}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                        onClose={confirmDialogProps.onClose}
                    />
                </>
            )}
        </>
    );
}
