import { ReactNode } from 'react';
import { IconButton, List, ListItem, ListProps, ListItemText } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { ZipCode } from '../../types/Location';

export const toUniqueZipCodes = (zipCodes: ZipCode[]): ZipCode[] => {
    const uniqueCodes = new Set<string>();

    return zipCodes.filter((zipCode: ZipCode) => {
        if (!uniqueCodes.has(zipCode.code)) {
            uniqueCodes.add(zipCode.code);
            return true;
        }

        return false;
    });

    // return zipCodes
    //     .filter(
    //         (zipCode: ZipCode, index: number) => zipCodes.indexOf(zipCode) === index
    //     );
};

export const parseZipCodes = (rawZipCodes: string): ZipCode[] => {
    const zipCodes: ZipCode[] = [];

    if (rawZipCodes) {
        if (rawZipCodes.includes(',')) {
            zipCodes.push(
                ...rawZipCodes.split(',').map((zipCode: string) => ({
                    code: zipCode.trim(),
                    population: 0,
                }))
            );
        } else {
            zipCodes.push(
                ...rawZipCodes.split('\n').map((zipCode: string) => ({
                    code: zipCode.trim(),
                    population: 0,
                }))
            );
        }
    }

    return toUniqueZipCodes(zipCodes);
};

export type ZipCodeListProps = {
    items: ZipCode[];
    onChange?: (items: ZipCode[]) => void;
    onDetach?: (item: ZipCode) => void;
    getItemLabel?: (item: ZipCode) => ReactNode;
} & ListProps;

export default function ZipCodeList(props: ZipCodeListProps) {
    const {
        items,
        onChange,
        onDetach,
        getItemLabel = (item: ZipCode): ReactNode => {
            return item?.code;
        },
        ...listProps
    } = props;

    return (
        <List {...listProps}>
            {items.map((item: ZipCode) => (
                <ListItem
                    key={item.code}
                    dense
                    disableGutters={false}
                    divider
                    secondaryAction={
                        onDetach && (
                            <IconButton
                                aria-label="detach zip code"
                                onClick={() => {
                                    if (onDetach) {
                                        onDetach(item);
                                    }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        )
                    }
                    sx={{ py: 1 }}
                >
                    <ListItemText primary={getItemLabel(item)} />
                </ListItem>
            ))}
        </List>
    );
}
