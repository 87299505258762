import { Typography } from '@mui/material';
import { Card, CardContent, CardHeader } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import CampaignTargetingLocationTabs from './CampaignTargetingLocationTabs';
import Row from '../../../../components/Row';

type CampaignTargetingLocationCardProps = {} & CampaignWizardStepProps;

export default function CampaignTargetingLocationCard(props: CampaignTargetingLocationCardProps) {
    const { campaign, onChange } = props;

    return (
        <Card id="campaign-wizard-targeting-location" variant="outlined">
            <CardHeader
                title={
                    <Row spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Location
                        </Typography>
                    </Row>
                }
            />
            <CardContent>
                <CampaignTargetingLocationTabs campaign={campaign} onChange={onChange} />
            </CardContent>
        </Card>
    );
}
