import { useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';

import { getAudienceSourceInventoryForecast } from '../../hooks/useAudienceSources';
import {
    AudienceSource,
    AudienceSourceFee,
    AudienceSourceCriteria,
    AudienceSourceInventoryForecast,
} from '../../types/AudienceSource';
import { AudienceSourceCategoryPairGroups } from '../../types/AudienceSourceCategory';
import { Card, CardContent, CardHeader } from '../../pages/campaign/partials/CampaignWizard/CampaignWizardStyles';
import { parseRawAudienceSourceCategoryPairs } from '../../hooks/useAudienceSourceCategories';

import { CDTextField } from '../CDTextField';
import CDGrid from '../CDGridPro';
import Row from '../Row';
import Utils from '../Utils';

function AudienceSourceSearchCriteriaCategoryLabel(props: { searchCriteria: AudienceSourceCriteria }) {
    const { searchCriteria } = props;
    const categories: AudienceSourceCategoryPairGroups = parseRawAudienceSourceCategoryPairs(searchCriteria.categories);

    return (
        <Box>
            {Object.keys(categories).map((category: string) => {
                const subCategories: string[] = categories[category];
                return (
                    <Row key={category} gap={1}>
                        <Typography variant="body1" color="text.secondary">
                            {category}
                        </Typography>

                        {subCategories.length > 0 && (
                            <>
                                <Typography variant="body1" color="text.secondary">
                                    /
                                </Typography>
                                <Typography variant="body1" color="text.primary">
                                    {subCategories[0]}
                                </Typography>
                            </>
                        )}

                        {subCategories.length > 1 && (
                            <>
                                <Typography variant="body1" color="text.secondary">
                                    {' '}
                                    and{' '}
                                </Typography>
                                <Typography variant="body1" color="text.primary">{`${
                                    subCategories.length - 1
                                } others`}</Typography>
                            </>
                        )}
                    </Row>
                );
            })}
        </Box>
    );
}

export function NoRowsOverlay() {
    return (
        <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '70px' }}>
            <Typography variant="subtitle2">Select Audience Categories to see audience results.</Typography>
        </Stack>
    );
}

export type AudienceSourceDataGridCardProps = {
    rows: AudienceSource[];
    selectedRows?: AudienceSource[];
    onSelectionsChanged?: (items: AudienceSource[]) => void;
    searchCriteria: AudienceSourceCriteria;
    changeSearchCriteria: (searchCriteria: AudienceSourceCriteria) => void;
};

export default function AudienceSourceDataGridCard(props: AudienceSourceDataGridCardProps) {
    const { changeSearchCriteria, onSelectionsChanged, searchCriteria, selectedRows } = props;

    const [selectionRows, setSelectionRows] = useState<AudienceSource[]>([]);
    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                headerName: 'Audience Segments',
                field: 'audienceName',
                flex: 1,
                width: 360,
            },
            {
                headerName: 'Daily Reach',
                field: 'dailyReach',
                align: 'right',
                width: 120,
                valueGetter: (value, row) => {
                    const audienceSource: AudienceSource = row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyReach'
                    );

                    if (forecast.upperBoundExclusive > 0) {
                        return forecast.upperBoundExclusive;
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        return forecast.lowerBoundInclusive;
                    }

                    return 0;
                },
                renderCell: (params) => {
                    const audienceSource: AudienceSource = params.row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyReach'
                    );

                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    let values: string[] = [];

                    if (forecast.upperBoundExclusive > 0) {
                        values.push(formatter.format(forecast.upperBoundExclusive));
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        values.push(formatter.format(forecast.lowerBoundInclusive));
                    }

                    return values.join(' - ');
                },
            },
            {
                headerName: 'Daily Impressions',
                field: 'dailyImpressions',
                align: 'right',
                width: 140,
                valueGetter: (value, row) => {
                    const audienceSource: AudienceSource = row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyImpressions'
                    );

                    if (forecast.upperBoundExclusive > 0) {
                        return forecast.upperBoundExclusive;
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        return forecast.lowerBoundInclusive;
                    }

                    return 0;
                },
                renderCell: (params) => {
                    const audienceSource: AudienceSource = params.row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyImpressions'
                    );

                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    let values: string[] = [];

                    if (forecast.upperBoundExclusive > 0) {
                        values.push(formatter.format(forecast.upperBoundExclusive));
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        values.push(formatter.format(forecast.lowerBoundInclusive));
                    }

                    return values.join(' - ');
                },
            },
            {
                headerName: 'Fee',
                headerAlign: 'right',
                field: 'fees',
                align: 'right',
                width: 140,
                valueGetter: (value, row) => {
                    const audienceSource: AudienceSource = row;
                    const fee: AudienceSourceFee | undefined = audienceSource.fees.find((fee: AudienceSourceFee) => {
                        if (searchCriteria.mediaType && searchCriteria.mediaType.toLowerCase()) {
                            switch (searchCriteria.mediaType) {
                                case 'Display':
                                    return ['WEB'].includes(fee.impressionSupplyType);

                                case 'Video':
                                    return ['OLV', 'STV'].includes(fee.impressionSupplyType);

                                default:
                                    return (
                                        searchCriteria.mediaType.toLowerCase() ===
                                        fee.impressionSupplyType.toLowerCase()
                                    );
                            }
                        }
                        return false;
                    });

                    if (fee && fee.amount > 0 && fee.scale > 0) {
                        return fee.amount / fee.scale;
                    }

                    return 0;
                },
                renderCell: (params) => {
                    const audienceSource: AudienceSource = params.row;
                    const fee: AudienceSourceFee | undefined = audienceSource.fees.find((fee: AudienceSourceFee) => {
                        if (searchCriteria.mediaType && searchCriteria.mediaType.toLowerCase()) {
                            switch (searchCriteria.mediaType) {
                                case 'Display':
                                    return ['WEB'].includes(fee.impressionSupplyType);

                                case 'Video':
                                    return ['OLV', 'STV'].includes(fee.impressionSupplyType);

                                default:
                                    return (
                                        searchCriteria.mediaType.toLowerCase() ===
                                        fee.impressionSupplyType.toLowerCase()
                                    );
                            }
                        }
                        return false;
                    });

                    if (fee && fee.amount > 0 && fee.scale > 0) {
                        return `${Utils.formatCurrency(fee.amount / fee.scale, 2)} ${fee.feeCalculationType}`;
                    }

                    return `${Utils.formatCurrency(0, 2)} CPM`;
                },
            },
        ];
    }, [searchCriteria]);

    const toGridRowId = (item: AudienceSource): string => item.audienceId as any as string;

    const getItemByRowId = (gridRowId: GridRowId): AudienceSource | null | undefined => {
        return props.rows.find((ie: AudienceSource) => {
            return toGridRowId(ie) === gridRowId;
        });
    };

    const sortRows = (_rows: AudienceSource[], _selectedRows: AudienceSource[]) => {
        return _rows.sort((a: AudienceSource, b: AudienceSource) => {
            if (
                _selectedRows.find((x: AudienceSource) => toGridRowId(x) === toGridRowId(a)) &&
                !_selectedRows.find((x: AudienceSource) => toGridRowId(x) === toGridRowId(b))
            ) {
                return -1;
            }
            if (
                !_selectedRows.find((x: AudienceSource) => toGridRowId(x) === toGridRowId(a)) &&
                _selectedRows.find((x: AudienceSource) => toGridRowId(x) === toGridRowId(b))
            ) {
                return 1;
            }
            return 0;
        });
    };

    const sortedSelectionRows = useMemo(
        () => sortRows([...selectionRows], selectedRows ?? []),
        [selectionRows, selectedRows]
    );

    const selectionModel = useMemo((): string[] => {
        return selectedRows ? selectedRows.map((o: AudienceSource) => toGridRowId(o)) : [];
    }, [selectedRows]);

    const hasSubCategory = (category: string): boolean => {
        // Not a mistake placing pipe symbol "|" at the end
        // as that indicates there is a category pair or
        // a sub category.
        return searchCriteria.categories.find((s: string) => s.includes(`${category}|`)) !== undefined;
    };

    useEffect(() => {
        const items: AudienceSource[] = props.rows
            .filter((o: AudienceSource) => {
                if (o.category) {
                    if (searchCriteria?.categories?.length) {
                        let categoryPair: string = `${o.category}|${o.subCategory}`;

                        if (!hasSubCategory(o.category)) {
                            categoryPair = o.category;
                        }

                        return searchCriteria.categories.includes(categoryPair);
                    }
                }

                return true;
            })
            .filter((o: AudienceSource) => {
                if (searchCriteria.searchText) {
                    if (o.audienceName) {
                        return o.audienceName.toLowerCase().indexOf(searchCriteria.searchText.toLowerCase()) > -1;
                    }
                }
                return true;
            });

        setSelectionRows(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCriteria]);

    const handleSelectionModel = (selectionModel: any): void => {
        if (onSelectionsChanged) {
            onSelectionsChanged(
                selectionModel.map((dataGridRowId: GridRowId) => {
                    return getItemByRowId(dataGridRowId);
                })
            );
        }
    };

    return (
        <Card variant="outlined" sx={{ width: 8 / 12 }}>
            <CardHeader
                title={
                    <Row spacing={0.5} alignItems="center">
                        <Typography variant="subtitle2" color="text.primary">
                            Select Audience Segments
                        </Typography>

                        <CDTextField
                            sx={{ width: '300px' }}
                            id="searchText"
                            value={searchCriteria.searchText}
                            autoComplete="off"
                            size="small"
                            placeholder="Search all audiences"
                            // onKeyDown={(event) => {
                            //     if (['Enter', 'NumpadEnter'].includes(event.code) === true) {
                            //         search();
                            //     }
                            // }}
                            onChange={(event) => {
                                changeSearchCriteria({
                                    ...searchCriteria,
                                    searchText: event.target.value,
                                });
                            }}
                        />
                    </Row>
                }
            />
            <CardContent>
                <Stack direction="column">
                    <Stack>
                        <Box sx={{ padding: 2 }}>
                            <AudienceSourceSearchCriteriaCategoryLabel searchCriteria={searchCriteria} />
                            <Typography
                                variant="subtitle2"
                                sx={
                                    {
                                        // visibility: selectedItems.length > 0 ? 'visible' : 'hidden',
                                    }
                                }
                            >
                                {selectedRows?.length ?? 0}/{selectionRows.length} selected
                            </Typography>
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            minHeight: 275,
                            width: '100%',
                            flexGrow: 1,
                        }}
                    >
                        <CDGrid
                            pageSize={15}
                            checkboxSelection={true}
                            keepNonExistentRowsSelected={true}
                            columns={columns}
                            rows={sortedSelectionRows}
                            getRowId={(params) => toGridRowId(params)}
                            rowSelectionModel={selectionModel}
                            selectionsChanged={handleSelectionModel}
                            slots={{
                                noRowsOverlay: NoRowsOverlay,
                            }}
                            PaperProps={{
                                elevation: 0,
                            }}
                        />
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}
