import { useCallback, useEffect, useRef, useState } from 'react';

import { Campaign, CampaignAdGroupAudienceSource } from '../types/Campaign';
import { Dealer } from '../types/Dealer';
import { AudienceSource, AudienceSourceInventoryForecast } from '../types/AudienceSource';
import ApiService from '../ApiService';

export function toCampaignAdGroupAudienceSources(
    audienceSources: AudienceSource[],
    adGroupAudienceSources: CampaignAdGroupAudienceSource[] = []
): CampaignAdGroupAudienceSource[] {
    return [
        ...adGroupAudienceSources.filter((x: CampaignAdGroupAudienceSource) => {
            return audienceSources.find((y: AudienceSource) => x.dspAudienceId === y.audienceId) !== undefined;
        }),
        ...audienceSources
            .filter((x: AudienceSource) => {
                return (
                    adGroupAudienceSources.find(
                        (y: CampaignAdGroupAudienceSource) => x.audienceId === y.dspAudienceId
                    ) === undefined
                );
            })
            .map((audienceSource: AudienceSource) => {
                return {
                    dspAudienceId: audienceSource.audienceId,
                };
            }),
    ];
}

export function toAudienceSources(adGroupAudienceSources: CampaignAdGroupAudienceSource[]): AudienceSource[] {
    let audienceSources: AudienceSource[] = [];

    if (adGroupAudienceSources.length > 0) {
        audienceSources.push(
            ...adGroupAudienceSources.map((adGroupAudienceSource: CampaignAdGroupAudienceSource) => {
                return {
                    audienceId: adGroupAudienceSource.dspAudienceId,
                    audienceName: '',
                    description: '',
                    category: '',
                    subCategory: '',
                    status: '',
                    providerId: '',
                    forecasts: {},
                    fees: [],
                };
            })
        );
    }

    return audienceSources;
}

export function getAudienceSourceInventoryForecast(
    audienceSource: AudienceSource,
    target: string,
    metric: string
): AudienceSourceInventoryForecast {
    let forecast: AudienceSourceInventoryForecast = {
        lowerBoundInclusive: 0,
        upperBoundExclusive: 0,
    };

    if (audienceSource?.forecasts?.inventoryForecasts?.[target]?.[metric]?.lowerBoundInclusive) {
        forecast.lowerBoundInclusive = audienceSource.forecasts.inventoryForecasts[target][metric].lowerBoundInclusive;
    }

    if (audienceSource?.forecasts?.inventoryForecasts?.[target]?.[metric]?.upperBoundExclusive) {
        forecast.upperBoundExclusive = audienceSource.forecasts.inventoryForecasts[target][metric].upperBoundExclusive;
    }

    return forecast;
}

export interface AudienceSources {
    //
    audienceSources: AudienceSource[];
    setAudienceSources(audienceSources: AudienceSource[]): void;
    fetchAudienceSources(): void;

    //
    getAudienceSourceName(audienceSource: AudienceSource): string;
    getCampaignAudienceSources(campaign: Campaign): AudienceSource[];

    //
    hydrateAudienceSource(unhydratedAudienceSource: AudienceSource): AudienceSource;
    hydrateAudienceSources(unhydratedAudienceSources: AudienceSource[]): AudienceSource[];
}

type useAudienceProps = {
    dealer: Dealer | null | undefined;
};

export default function useAudienceSources(props: useAudienceProps): AudienceSources {
    const { dealer } = props;
    const [audienceSources, setAudienceSources] = useState<AudienceSource[]>([]);
    const isFetched = useRef<boolean>(false);

    const fetchAudienceSources = useCallback(() => {
        if (dealer) {
            if (isFetched.current === false) {
                ApiService.getGatewayAudiences(dealer.dspAdvertiser).then((response) => {
                    const {
                        data: { data },
                    } = response;

                    if (data?.audiences?.length > 0) {
                        setAudienceSources(data.audiences);
                    }
                });
            }

            isFetched.current = true;
        } else {
            setAudienceSources([]);
        }
    }, [dealer]);

    const getAudienceSourceName = (audienceSource: AudienceSource): string => {
        return hydrateAudienceSource(audienceSource)?.audienceName;
    };

    const hydrateAudienceSource = (unhydratedAudienceSource: AudienceSource): AudienceSource => {
        if (audienceSources.length > 0) {
            const hydratedAudienceSource = audienceSources.find(
                (o: AudienceSource) => o.audienceId === unhydratedAudienceSource.audienceId
            );

            if (hydratedAudienceSource) {
                return hydratedAudienceSource;
            }
        }

        return unhydratedAudienceSource;
    };

    const hydrateAudienceSources = (unhydratedAudienceSources: AudienceSource[]): AudienceSource[] => {
        if (audienceSources.length > 0) {
            return unhydratedAudienceSources.map((unhydratedAudienceSource: AudienceSource) =>
                hydrateAudienceSource(unhydratedAudienceSource)
            );
        }

        return unhydratedAudienceSources;
    };

    const getCampaignAudienceSources = (campaign: Campaign): AudienceSource[] => {
        let hydratedAudienceSources: AudienceSource[] = [];

        if (campaign.adGroups.length > 0) {
            if (campaign.adGroups[0]?.adGroupAudienceSources) {
                hydratedAudienceSources = hydrateAudienceSources(
                    toAudienceSources(campaign.adGroups[0].adGroupAudienceSources)
                );
            }
        }

        return hydratedAudienceSources;
    };

    // useEffect(() => {
    //     fetchAudienceSources();
    // }, []);

    useEffect(() => {
        isFetched.current = false;
    }, [dealer]);

    return {
        audienceSources,
        setAudienceSources,
        fetchAudienceSources,
        getAudienceSourceName,
        getCampaignAudienceSources,
        hydrateAudienceSource,
        hydrateAudienceSources,
    };
}
