import { ChangeEvent, useEffect, useMemo } from 'react';
import { Box, FormGroup, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { GridRowEntry } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { gridFilteredSortedRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';

import { InventorySource, InventorySourceCriteria } from '../../types/Inventory';
import { Card, CardContent, CardHeader } from '../../pages/campaign/partials/CampaignWizard/CampaignWizardStyles';
import { CDTextField } from '../CDTextField';
import { getGridAllOperators } from '../../hooks/useDataGridFilter';
import { useInventorySourceTypes } from '../../hooks/useInventorySourceTypes';
import CDGrid, { CDGridProProps } from '../CDGridPro';
import Row from '../Row';
import Utils from '../Utils';

export function NoRowsOverlay() {
    return (
        <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '70px' }}>
            <Typography variant="subtitle2">Select Inventory Source(s) to see inventory exchange results.</Typography>
        </Stack>
    );
}

export type InventorySourceDataGridCardProps = {
    rows: InventorySource[];
    selectedRows?: InventorySource[];
    onChangeSelections?: (items: InventorySource[]) => void;
    searchCriteria: InventorySourceCriteria;
    changeSearchCriteria: (searchCriteria: InventorySourceCriteria) => void;
} & Omit<CDGridProProps<InventorySource>, 'columns'>;

export default function InventorySourceDataGridCard(props: InventorySourceDataGridCardProps) {
    const { changeSearchCriteria, onChangeSelections, rows, searchCriteria, selectedRows, ...dataGridProps } = props;

    const apiRef = useGridApiRef();
    const { inventorySourceTypes, setInventorySourceTypes, identifyInventorySourceTypes, toInventorySourceTypeNames } =
        useInventorySourceTypes();

    const columns = useMemo(() => {
        let _columns: GridColDef[] = [
            {
                headerName: 'Name',
                field: 'name',
                flex: 1,
                width: 360,
                filterOperators: getGridAllOperators(),
            },
            {
                headerName: 'Inventory Type',
                field: 'inventoryType',
                // flex: 1,
                width: 120,
            },
            {
                headerName: 'Device',
                field: 'deviceType',
                // flex: 1,
                width: 100,
            },
        ];

        if (searchCriteria.inventorySourceTypes.includes('DEAL')) {
            _columns.push(
                ...[
                    {
                        headerName: 'Price Type',
                        field: 'deal.dealPrice.priceType',
                        // flex: 1,
                        width: 100,
                        valueGetter: (value: string, row: InventorySource) => {
                            return row?.deal?.dealPrice?.priceType;
                        },
                    },
                    {
                        headerName: 'Amount',
                        field: 'deal.dealPrice.amount',
                        // flex: 1,
                        width: 100,
                        valueGetter: (value: string, row: InventorySource) => {
                            return Utils.formatCurrency(row?.deal?.dealPrice?.amount);
                        },
                    },
                    {
                        headerName: 'Deal Type',
                        field: 'deal.dealType',
                        // flex: 1,
                        width: 100,
                        valueGetter: (value: string, row: InventorySource) => {
                            return row?.deal?.dealType;
                        },
                    },
                ]
            );
        }

        return _columns;
    }, [searchCriteria]);

    const toGridRowId = (item: InventorySource): string => item.id;

    // const getItemByRowId = (gridRowId: GridRowId): InventorySource | null | undefined => {
    //     return props.rows.find((ie: InventorySource) => {
    //         return toGridRowId(ie) === gridRowId;
    //     });
    // };

    const sortRows = (_rows: InventorySource[], _selectedRows: InventorySource[]) => {
        return _rows.sort((a: InventorySource, b: InventorySource) => {
            if (
                _selectedRows.find((x: InventorySource) => x.id === a.id) &&
                !_selectedRows.find((x: InventorySource) => x.id === b.id)
            ) {
                return -1;
            }
            if (
                !_selectedRows.find((x: InventorySource) => x.id === a.id) &&
                _selectedRows.find((x: InventorySource) => x.id === b.id)
            ) {
                return 1;
            }
            return 0;
        });
    };

    const sortedRows = useMemo(() => sortRows([...rows], selectedRows ?? []), [rows, selectedRows]);

    const selectionModel = useMemo((): string[] => {
        return selectedRows ? selectedRows.map((o: InventorySource) => toGridRowId(o)) : [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    useEffect(() => {
        setInventorySourceTypes(selectedRows ? identifyInventorySourceTypes(selectedRows) : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    const handleSelectionModel = (selectionModel: any): void => {
        if (onChangeSelections) {
            const gridFilteredSortedRowEntries: GridRowEntry[] = gridFilteredSortedRowEntriesSelector(apiRef);

            onChangeSelections(
                gridFilteredSortedRowEntries
                    .filter((gridRowEntry: GridRowEntry) => {
                        return selectionModel.includes(gridRowEntry.id);
                    })
                    .map((gridRowEntry: GridRowEntry) => {
                        return gridRowEntry.model as InventorySource;
                    })
            );
        }
    };

    return (
        <Card variant="outlined" sx={{ width: 8 / 12 }}>
            <CardHeader
                sx={{ py: 1 }}
                title={
                    <Row spacing={0.5} alignItems="center">
                        <Typography variant="subtitle2" color="text.primary">
                            Select Advertising Inventory Supply
                        </Typography>

                        <CDTextField
                            sx={{ width: '300px' }}
                            id="searchText"
                            value={searchCriteria.searchText}
                            autoComplete="off"
                            size="small"
                            placeholder="Search all inventory"
                            // onKeyDown={(event) => {
                            //     if (['Enter', 'NumpadEnter'].includes(event.code) === true) {
                            //         search();
                            //     }
                            // }}
                            onChange={(event) => {
                                changeSearchCriteria({
                                    ...searchCriteria,
                                    searchText: event.target.value,
                                });
                            }}
                        />

                        {searchCriteria.mediaType === 'Video' && (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            defaultChecked={searchCriteria?.runPrimeVideoOnly ?? false}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                changeSearchCriteria({
                                                    ...searchCriteria,
                                                    runPrimeVideoOnly: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                    label="Run Prime Video only"
                                />
                            </FormGroup>
                        )}
                    </Row>
                }
            />
            <CardContent>
                <Stack direction="column">
                    <Stack>
                        <Box sx={{ padding: 2 }}>
                            {inventorySourceTypes.length > 0 && (
                                <Typography variant="subtitle1">
                                    {toInventorySourceTypeNames(inventorySourceTypes).join(', ')}
                                </Typography>
                            )}
                            <Typography
                                variant="subtitle2"
                                sx={
                                    {
                                        // visibility: selectedItems.length > 0 ? 'visible' : 'hidden',
                                    }
                                }
                            >
                                {selectedRows?.length ?? 0}/{rows.length} selected
                            </Typography>
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            minHeight: 275,
                            width: '100%',
                            flexGrow: 1,
                        }}
                    >
                        <CDGrid
                            apiRef={apiRef}
                            pageSize={15}
                            checkboxSelection={true}
                            columns={columns}
                            rows={sortedRows}
                            getRowId={(params) => toGridRowId(params)}
                            rowSelectionModel={selectionModel}
                            selectionsChanged={handleSelectionModel}
                            slots={{
                                noRowsOverlay: NoRowsOverlay,
                            }}
                            PaperProps={{
                                elevation: 0,
                            }}
                            {...dataGridProps}
                        />
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}
