import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, FormControl, Grid, Typography } from '@mui/material';

import { CampaignWizardStepProps } from './CampaignWizard';
import { Location } from '../../../../types/Location';
import { CDTextField } from '../../../../components/CDTextField';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import Column from '../../../../components/Column';
import LocationCheckboxList from '../../../../components/Location/LocationCheckboxList';
import LocationList from '../../../../components/Location/LocationList';

export const toCampaignZipcodes = (locations: Location[]): string => {
    return locations.map((location: Location) => location.id).join(',');
};

type CampaignLocationListProps = {
    items: Location[];
    onChangeMany?: (items: Location[]) => void;
    onAttachMany?: (items: Location[]) => void;
    onDetachMany?: (items: Location[]) => void;
    onSearch?: (searchText: string) => void;
    loading?: boolean;
} & CampaignWizardStepProps;

export default function CampaignLocationList(props: CampaignLocationListProps) {
    const { loading, onAttachMany, onChangeMany, onDetachMany, onSearch, items } = props;

    const { $locations } = useCampaignWizardContext();
    const { locations: selections = [], fetchLocations: fetchSelections = () => {} } = $locations || {};

    const [searchText, setSearchText] = useState<string>('');
    const [filteredSelections, setFilteredSelections] = useState<Location[]>([]);

    useEffect(() => {
        if (fetchSelections) {
            fetchSelections();
        }
    }, [fetchSelections]);

    const searchSelections = useCallback(
        (rawSearchText: string = '') => {
            if (rawSearchText.length > 0) {
                setFilteredSelections([
                    ...selections
                        .filter((item: Location) => {
                            const regex = new RegExp(rawSearchText, 'i');
                            return regex.test(item.name);
                        })
                        .slice(0, 50),
                ]);
            } else {
                if (selections.length > 50) {
                    setFilteredSelections([...selections.slice(0, 50)]);
                }
            }
        },
        [selections]
    );

    const handleChangeMany = useCallback(
        (items: Location[]) => {
            if (onChangeMany) {
                onChangeMany(items);
            }
        },
        [onChangeMany]
    );

    const handleAttachMany = useCallback(
        (items: Location[]) => {
            if (onAttachMany) {
                onAttachMany(items);
            }
        },
        [onAttachMany]
    );

    const handleDetachMany = useCallback(
        (items: Location[]) => {
            if (onDetachMany) {
                onDetachMany(items);
            }
        },
        [onDetachMany]
    );

    const handleSearchSelections = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        searchSelections(event.target.value);

        if (onSearch) {
            onSearch(searchText);
        }
    };

    // useEffect(() => {
    //     // Initially trigger the search selections
    //     searchSelections();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <Column>
                    <Box sx={{ px: 2, pt: 3 }}>
                        <FormControl fullWidth size="small">
                            <CDTextField
                                label="Search"
                                value={searchText}
                                onChange={handleSearchSelections}
                                shrinkLabel
                                multiline
                                rows={3}
                                placeholder="Enter city, state or zip code"
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ px: 2 }}>
                        <Typography variant="caption" color="text.primary" sx={{ mb: 6 }}>
                            Selects Location(s) to target
                        </Typography>
                    </Box>

                    <Box>
                        <Divider />

                        {loading === true && (
                            <Column alignItems="center" justifyContent="center" sx={{ height: 200 }}>
                                <CircularProgress />
                            </Column>
                        )}
                        {loading === false && (
                            <>
                                {filteredSelections.length > 0 && (
                                    <LocationCheckboxList
                                        items={items}
                                        selections={filteredSelections}
                                        onAttach={(location: Location) => {
                                            if (!items.find((o: Location) => o.id === location.id)) {
                                                items.push(location);
                                                handleAttachMany([location]);
                                                handleChangeMany([...items]);
                                            }
                                        }}
                                        sx={{
                                            bgcolor: 'background.paper',
                                            height: 200,
                                            overflow: 'auto',
                                            position: 'relative',
                                        }}
                                    />
                                )}
                                {filteredSelections.length === 0 && (
                                    <Column alignItems="center" justifyContent="center" sx={{ height: 200 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            No results
                                        </Typography>
                                    </Column>
                                )}
                            </>
                        )}
                    </Box>
                </Column>
            </Grid>

            <Grid item xs={6} sx={{ borderLeft: 1, borderColor: 'divider' }}>
                <Column>
                    <LocationList
                        items={items}
                        onDetach={(location: Location) => {
                            const index = items.indexOf(location);

                            if (index > -1) {
                                items.splice(index, 1);
                                handleDetachMany([location]);
                                handleChangeMany([...items]);
                            }
                        }}
                        sx={{
                            bgcolor: 'background.paper',
                            height: 400,
                            overflow: 'auto',
                            position: 'relative',
                        }}
                    />
                </Column>
            </Grid>
        </Grid>
    );
}
