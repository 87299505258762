import { useCallback, useRef } from 'react';
import { point } from '@turf/helpers';
import buffer from '@turf/buffer';

import { Location } from '../types/Location';
import {
    codeToCenterPoint,
    polygonToBoundaries,
    toUniqueZipFeatures,
    ZipFeature,
    ZipFeatureCollection,
    // ZipFeatureProperties,
} from '../components/Map/resolver';
import { featureToZipCode, ZipCode } from '../components/Map/Map';

export function useLocationBoundaries(
    props: {
        baseUrl?: string;
        country?: string;
        getCenterPoint?: (country: string, code: string) => Promise<{ center: [number, number] }>;
    } = {}
) {
    const { baseUrl: baseUrlProp = '', country = 'United States', getCenterPoint } = props || {};

    const baseUrl =
        baseUrlProp === '' || baseUrlProp.charAt(baseUrlProp.length - 1) === '/' ? baseUrlProp : `${baseUrlProp}/`;

    const $getCenterPoint = useRef(getCenterPoint);
    $getCenterPoint.current = getCenterPoint;

    const $fetchCenterPoint = useRef<(country: string, code: string) => Promise<any>>();
    $fetchCenterPoint.current = async (country: string, code: string) => {
        if ($getCenterPoint.current) {
            return await $getCenterPoint.current(country, code);
        } else {
            return await codeToCenterPoint(country, code, async (path) => {
                const response = await fetch(`${baseUrl}${path}.json`);
                return await response.json();
            });
        }
    };

    const getLocationBoundaryZipCodes = useCallback(
        async (location: Location, radius: number = 30): Promise<ZipCode[]> => {
            const zipCodes: ZipCode[] = [];

            if (!location?.zipCode) {
                return zipCodes;
            }

            const centerPoint = await $fetchCenterPoint.current?.(country, location.zipCode).catch((error) => {});

            if (centerPoint?.center) {
                let buffered = buffer(point(centerPoint.center), radius, { units: 'miles' });

                const polygon = await polygonToBoundaries(buffered.geometry, async (path) => {
                    const response = await fetch(`${baseUrl}${path}.json`);
                    return await response.json();
                });

                if (polygon?.boundaries?.features?.length) {
                    polygon.boundaries.features.forEach((feature: ZipFeature) => {
                        const zipCode: ZipCode = featureToZipCode(feature);
                        zipCodes.push(zipCode);
                    });
                }
            }

            return zipCodes;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return {
        getLocationBoundaryZipCodes,
    };
}

export type MapBoundariesProps = {
    targetZipCodes: string;
    zipCodes?: string;
    baseUrl?: string;
    country?: string;
    radius?: number;
    getCenterPoint?: (country: string, code: string) => Promise<{ center: [number, number] }>;
    onGetCenterPointError?: () => void;
};

export default function useMapBoundaries(props: MapBoundariesProps) {
    const {
        targetZipCodes,
        zipCodes,
        getCenterPoint,
        baseUrl: baseUrlProp = '',
        country = 'United States',
        radius = 1,
        onGetCenterPointError,
    } = props;

    const baseUrl =
        baseUrlProp === '' || baseUrlProp.charAt(baseUrlProp.length - 1) === '/' ? baseUrlProp : `${baseUrlProp}/`;

    const getCenterPointRef = useRef(getCenterPoint);
    getCenterPointRef.current = getCenterPoint;

    const onGetCenterPointErrorRef = useRef(onGetCenterPointError);
    onGetCenterPointErrorRef.current = onGetCenterPointError;

    const fetchCenterPointRef = useRef<(country: string, code: string) => Promise<any>>();
    fetchCenterPointRef.current = async (country: string, code: string) => {
        if (getCenterPointRef.current) {
            return await getCenterPointRef.current(country, code);
        } else {
            return await codeToCenterPoint(country, code, async (path) => {
                const response = await fetch(`${baseUrl}${path}.json`);
                return await response.json();
            });
        }
    };

    const getBoundaryRawZipCodes = (boundaries: ZipFeatureCollection): string[] => {
        return boundaries.features.map(featureToZipCode).map((zipCode: ZipCode) => zipCode.code);
    };

    const getMissingBoundaryRawZipCodes = (rawZipCodes: string[], boundaryRawZipCodes: string[]): string[] => {
        return rawZipCodes.filter((zipCode) => !boundaryRawZipCodes.includes(zipCode));
    };

    const getRawZipCodeBoundaries = async (rawZipCodes: string[]): Promise<ZipFeatureCollection> => {
        const result = await fetch('/api/geographicTargeting/data/boundaries', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rawZipCodes),
        });

        return await result.json();
    };

    const getBoundaries = useCallback(
        async (
            polygon: GeoJSON.Polygon
        ): Promise<{
            boundaries: ZipFeatureCollection;
        }> => {
            let boundaries: ZipFeatureCollection = {
                type: 'FeatureCollection',
                features: [],
            };

            if (targetZipCodes) {
                const _targetZipCodes = targetZipCodes.split(',').map(String);

                const centerResults = await Promise.all(
                    _targetZipCodes.map(async (_zipCode: string) => {
                        const result = await fetchCenterPointRef.current?.(country, _zipCode).catch((error) => {
                            onGetCenterPointErrorRef.current?.();
                        });

                        return result?.center ?? null;
                    })
                );

                const centers = centerResults.filter((center: any) => center !== null);

                const results = await Promise.all(
                    centers.map(async (center) => {
                        let buffered = buffer(point(center), radius, { units: 'miles' });

                        return await polygonToBoundaries(buffered.geometry, async (path) => {
                            const response = await fetch(`${baseUrl}${path}.json`);
                            return await response.json();
                        });
                    })
                );

                if (results) {
                    results.forEach((result) => {
                        if (result.boundaries.features.length > 0) {
                            boundaries.features.push(
                                ...result.boundaries.features.map((feature) => {
                                    if ('id' in feature) {
                                        feature.id = (feature.id as number) + boundaries.features.length;
                                    }
                                    return feature;
                                })
                            );
                        }
                    });

                    boundaries.features = toUniqueZipFeatures(boundaries.features);
                }
            }

            if (zipCodes) {
                const boundaryRawZipCodes: string[] = getBoundaryRawZipCodes(boundaries);
                const rawZipCodes: string[] = zipCodes.split(',').map(String);
                const missingBoundaryRawZipCodes: string[] = getMissingBoundaryRawZipCodes(
                    rawZipCodes,
                    boundaryRawZipCodes
                );

                if (missingBoundaryRawZipCodes.length) {
                    const missingBoundaries: ZipFeatureCollection = await getRawZipCodeBoundaries(
                        missingBoundaryRawZipCodes
                    );

                    boundaries.features.push(
                        ...missingBoundaries.features.map((item: ZipFeature, id: number) => {
                            const properties = {
                                ...item.properties,
                                key: 'ZCTA5CE10',
                            };
                            return { ...item, id, properties };
                        })
                    );

                    boundaries.features = toUniqueZipFeatures(boundaries.features);
                }
            }

            return { boundaries: boundaries };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [targetZipCodes, zipCodes]
    );

    return {
        getBoundaries,
        getBoundaryRawZipCodes,
    };
}
