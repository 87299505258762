import { TableContainer } from '../../../components/Table';
import Column from '../../../components/Column';

import { Campaign } from '../../../types/Campaign';
import CampaignSummaryCreativeTable from './CampaignSummaryCreativeTable';
import CampaignSummarySetupTable from './CampaignSummarySetupTable';
import CampaignSummaryTargetingTable from './CampaignSummaryTargetingTable';

type CampaignSummaryProps = {
    campaign: Campaign;
};

export default function CampaignSummary(props: CampaignSummaryProps) {
    const { campaign } = props;

    return (
        <Column gap={3}>
            <TableContainer>
                <CampaignSummarySetupTable campaign={campaign} />
            </TableContainer>

            <TableContainer>
                <CampaignSummaryTargetingTable campaign={campaign} />
            </TableContainer>

            <TableContainer>
                <CampaignSummaryCreativeTable campaign={campaign} />
            </TableContainer>
        </Column>
    );
}
