import { useEffect, useMemo, useState } from 'react';
import { Grid, RadioGroup, SvgIconProps } from '@mui/material';
import {
    LocalGroceryStoreOutlined as LocalGroceryStoreOutlinedIcon,
    Storefront as StorefrontIcon,
    VolumeUpTwoTone as VolumeUpTwoToneIcon,
} from '@mui/icons-material';

import { Code } from '../../../types/Code';
import { RadioCard, RadioCardGroupProps } from '../../../components/RadioCard';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';

type CampaignGoalTypeRadioGroupProps = {
    filter?(codes: Code[]): Code[];
} & RadioCardGroupProps;

export default function CampaignGoalTypeRadioGroup(props: CampaignGoalTypeRadioGroupProps) {
    const { disabled, filter, ...radioGroupProps } = props;

    const { $goalTypeCodes } = useCampaignWizardContext();
    const { codes = [], defaultCode = null, fetchCodes = () => {} } = $goalTypeCodes || {};

    const [filteredCodes, setFilteredCodes] = useState<Code[]>([]);

    const defaultValue = useMemo(() => {
        return defaultCode ? defaultCode.code : '';
    }, [defaultCode]);

    useEffect(() => {
        fetchCodes();
    }, [fetchCodes]);

    useEffect(() => {
        if (codes && codes.length > 0) {
            setFilteredCodes(filter ? filter(codes) : codes);
        } else {
            setFilteredCodes([]);
        }
    }, [codes, filter]);

    const getCodeIcon = (code: Code): React.ComponentType<SvgIconProps> | undefined => {
        switch (code.code) {
            case 'Awareness':
                return VolumeUpTwoToneIcon;

            case 'Consideration':
                return StorefrontIcon;

            case 'Conversions':
                return LocalGroceryStoreOutlinedIcon;
        }

        return undefined;
    };

    const getCodeLabel = (code: Code): string => {
        switch (code.code) {
            case 'Awareness':
                return 'Awareness';

            case 'Consideration':
                return 'Consideration';

            case 'Conversions':
                return 'Conversions';
        }

        return code.description;
    };

    const getCodeDescription = (code: Code): string => {
        switch (code.code) {
            case 'Awareness':
                return 'Expose your brand to potential customers. Recommended for OTT, STV, OLV or Audio campaigns.';

            case 'Consideration':
                return 'Drive potential customers to your store or website. Recommend for OLV or Display campaigns.';

            case 'Conversions':
                return 'Drive purchases, leads, and other conversions. Recommended for Display campaigns only.';
        }

        return code.description;
    };

    return (
        <RadioGroup defaultValue={defaultValue} {...radioGroupProps} value={props.value}>
            {filteredCodes && (
                <Grid container spacing={3}>
                    {filteredCodes.map((code: Code) => {
                        return (
                            <Grid key={code.code} item xs={4}>
                                <RadioCard
                                    direction="row"
                                    required={true}
                                    disabled={disabled}
                                    value={code.code}
                                    description={getCodeDescription(code)}
                                    label={getCodeLabel(code)}
                                    LabelIcon={getCodeIcon(code)}
                                    CardProps={{
                                        sx: {
                                            '& .MuiCardContent-root': {
                                                minHeight: 140,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </RadioGroup>
    );
}
