import { deepmerge } from '@mui/utils';
import { InputAdornment } from '@mui/material';
import { AttachMoney as AttachMoneyIcon } from '@mui/icons-material';

import { CDTextField, CDTextFieldProps } from './CDTextField';

export type CDCurrencyFieldProps = CDTextFieldProps & {
    disableIcon?: boolean;
};

export function CDCurrencyField(props: CDCurrencyFieldProps) {
    const { disableIcon, ...textFieldProps } = props;
    const textFieldSxProps = deepmerge(props?.sx ?? {}, {
        '& .MuiOutlinedInput-root': {
            paddingLeft: 0,
        },
    });

    return (
        <CDTextField
            type="number"
            variant="outlined"
            InputProps={{
                startAdornment: !disableIcon && (
                    <InputAdornment position="end">
                        <AttachMoneyIcon
                            fontSize="small"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                            }}
                        />
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{ shrink: true }}
            {...textFieldProps}
            sx={textFieldSxProps}
        />
    );
}
