export const INVENTORY_SOURCE_TYPES = {
    AMAZON: 'AMAZON',
    THIRD_PARTY_EXCHANGE: 'THIRD_PARTY_EXCHANGE',
};

export type InventorySourceType = {
    code: string;
    name: string;
};

export type InventoryType = 'DISPLAY' | 'VIDEO' | 'UNKNOWN';

export class InventorySource {
    deviceType: string = '';
    id: string = '';
    inventorySourceType: string = '';
    inventoryType: InventoryType | string = '';
    name: string = '';
    deal?: InventorySourceDeal | null | undefined;
}

export class InventorySourceTypeGroup {
    inventorySourceType: string = '';
    inventorySources: InventorySource[] = [];
}

export interface InventorySourceCriteria {
    [key: string]: any;
    searchText: string;
    mediaType: string;
    inventorySourceTypes: string[];
    runPrimeVideoOnly?: boolean;
    page?: number;
    pageSize?: number;
    sort?: string;
}

export interface InventorySourceDealPrice {
    amount: number;
    currency: string;
    priceType: string;
}

export interface InventorySourceDeal {
    id: string;
    dealBudget: number;
    dealStatus: string;
    dealType: string;
    endDateTime: Date;
    exchangeId: number;
    externalDealId: number;
    inventoryType: string;
    marketplaceDeal: string;
    name: string;
    publisherName: string;
    recommendedDeal: string;
    startDateTime: Date;
    targetedPgDeal: number;
    dealPrice?: InventorySourceDealPrice | null | undefined;
}
