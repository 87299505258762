import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Card, CardContent } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { Dealer } from '../../../../types/Dealer';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import CampaignLocationZipCodeList from './CampaignLocationZipCodeList';
import Column from '../../../../components/Column';
import { ZipCode } from '../../../../types/Location';
import Map from '../../../../components/Location/ZipCodeMap';

type CampaignLocationZipCodeTabProps = {
    zipCodes: ZipCode[];
    targetZipCodes: string;
    onChangeZipCodes?: (items: ZipCode[]) => void;
    onAttachZipCodes?: (items: ZipCode[]) => void;
    onDetachZipCodes?: (items: ZipCode[]) => void;
} & CampaignWizardStepProps;

export default function CampaignLocationZipCodeTab(props: CampaignLocationZipCodeTabProps) {
    const { campaign, onAttachZipCodes, onDetachZipCodes, onChange, onChangeZipCodes, zipCodes, targetZipCodes } =
        props;
    const dealer = campaign.dealer;
    const { $campaignGeographyTargeting, $campaignZipCodes } = useCampaignWizardContext();

    // const [centerPoint, setCenterPoint] = useState<string>('');
    const [mapCenter, setMapCenter] = useState<string>('');

    function getAddress(dealer: Dealer, zipcodes?: string): string {
        let address = '';
        if (dealer) {
            if (dealer.address) address = dealer.address;
            if (dealer.city) address += ' ' + dealer.city;
            if (dealer.state) address += ' ' + dealer.state;
            if (dealer.zip) address += ' ' + dealer.zip;
        }
        if (address === '' && zipcodes && zipcodes.length > 0) {
            address = zipcodes.split(',')[0];
        }
        return address;
    }

    function zipCodesLoaded(results: ZipCode[]) {
        // const zipCodeString = results.map(({ code }) => code).join(',');
        // const population = results.reduce((acc, r) => acc + r.population, 0);
        // onChange({
        //     zipcodes: zipCodeString,
        //     population: Math.round(population / 1000) * 1000,
        // });
    }

    function zipCodeClick(targetZipCode: ZipCode) {
        if ($campaignGeographyTargeting) {
            if ($campaignGeographyTargeting.hasZipCode(targetZipCode)) {
                $campaignGeographyTargeting.detachZipCode(targetZipCode);
            }

            onChange({
                geographyTargeting: $campaignGeographyTargeting.toRawZipCodes(),
            });
        }

        if ($campaignZipCodes) {
            if ($campaignZipCodes.hasZipCode(targetZipCode)) {
                $campaignZipCodes.detachZipCode(targetZipCode);
            } else {
                $campaignZipCodes.attachZipCode(targetZipCode);
            }

            onChange({
                zipcodes: $campaignZipCodes.toRawZipCodes(),
            });
        }
    }

    const handleChangeZipCodes = useCallback(
        (items: ZipCode[]): void => {
            if (onChangeZipCodes) {
                onChangeZipCodes(items);
            }
        },
        [onChangeZipCodes]
    );

    const handleAttachZipCodes = (attachedItems: ZipCode[]): void => {
        if (onAttachZipCodes) {
            onAttachZipCodes(attachedItems);
        }

        if ($campaignGeographyTargeting) {
            $campaignGeographyTargeting.attachZipCodes(attachedItems);

            onChange({
                geographyTargeting: $campaignGeographyTargeting.toRawZipCodes(),
            });
        }

        if ($campaignZipCodes) {
            $campaignZipCodes.attachZipCodes(attachedItems);

            console.log('$campaignZipCodes.toRawZipCodes()');
            console.log($campaignZipCodes.toRawZipCodes());

            onChange({
                zipcodes: $campaignZipCodes.toRawZipCodes(),
            });
        }
    };

    const handleDetachZipCodes = (detachedItems: ZipCode[]): void => {
        if (onDetachZipCodes) {
            onDetachZipCodes(detachedItems);
        }

        if ($campaignGeographyTargeting) {
            $campaignGeographyTargeting.detachZipCodes(detachedItems);

            onChange({
                geographyTargeting: $campaignGeographyTargeting.toRawZipCodes(),
            });
        }

        if ($campaignZipCodes) {
            $campaignZipCodes.detachZipCodes(detachedItems);

            onChange({
                zipcodes: $campaignZipCodes.toRawZipCodes(),
            });
        }
    };

    useEffect(() => {
        let rawCenterPoint = '';

        if (targetZipCodes.length > 0) {
            rawCenterPoint = targetZipCodes.split(',').map(String)[0];
        }

        if (campaign?.geographyTargeting?.length) {
            rawCenterPoint = campaign.geographyTargeting.split(',').map(String)[0];
        }

        if (!rawCenterPoint.length) {
            if (dealer) {
                rawCenterPoint = getAddress(dealer, campaign.zipcodes);
            }
        }

        if (rawCenterPoint.length) {
            // setCenterPoint(rawCenterPoint);
            setMapCenter(rawCenterPoint);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer, campaign.geographyTargeting, campaign.zipcodes, targetZipCodes]);

    // const handleRecenterLocation = useCallback((): void => {
    //     let rawCenterPoint = '';

    //     if (campaign.geographyTargeting.length > 0) {
    //         rawCenterPoint = campaign.geographyTargeting.split(',').map(String)[0];
    //     }

    //     setMapCenter(rawCenterPoint ? rawCenterPoint : centerPoint);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [campaign.zipcodes, centerPoint]);

    // useEffect(() => {
    //     handleRecenterLocation();
    // }, [handleRecenterLocation]);

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderTopWidth: 0,
                }}
            >
                <CampaignLocationZipCodeList
                    campaign={campaign}
                    onChange={onChange}
                    items={zipCodes}
                    onChangeMany={handleChangeZipCodes}
                    onAttachMany={handleAttachZipCodes}
                    onDetachMany={handleDetachZipCodes}
                />
            </Card>

            <Card variant="outlined">
                <CardContent sx={{ p: 2 }}>
                    <Column gap={2}>
                        <Typography variant="body2">Map View for Zip Codes</Typography>

                        <Box
                            sx={{
                                height: '55vh',
                            }}
                        >
                            <Map
                                radius={campaign.zipcodeRadius ? campaign.zipcodeRadius : 30}
                                onZipClick={zipCodeClick}
                                onZipsLoaded={zipCodesLoaded}
                                zipCode={mapCenter}
                                zipCodes={campaign.zipcodes}
                                targetZipCodes={targetZipCodes}
                            />
                        </Box>
                    </Column>
                </CardContent>
            </Card>
        </>
    );
}
