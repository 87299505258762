import { Campaign } from '../../../../types/Campaign';

import CampaignSetupAdvertiserCard from './CampaignSetupAdvertiserCard';
import CampaignSetupDeliveryCard from './CampaignSetupDeliveryCard';
import CampaignSetupFrequencyCard from './CampaignSetupFrequencyCard';
import CampaignSetupInventoryCard from './CampaignSetupInventoryCard';
import CampaignSetupOptimizationCard from './CampaignSetupOptimizationCard';
import CampaignSetupPlanCard from './CampaignSetupPlanCard';

import Column from '../../../../components/Column';

export type CampaignWizardSetupStepProps = {
    campaign: Campaign;
    onChange(value: Partial<Campaign>): void;
};

export default function CampaignWizardSetupStep(props: CampaignWizardSetupStepProps) {
    const { campaign, onChange } = props;

    return (
        <Column gap={3}>
            <CampaignSetupAdvertiserCard campaign={campaign} onChange={onChange} />

            <CampaignSetupPlanCard campaign={campaign} onChange={onChange} />

            <CampaignSetupOptimizationCard campaign={campaign} onChange={onChange} />

            <CampaignSetupInventoryCard campaign={campaign} onChange={onChange} />

            <CampaignSetupDeliveryCard campaign={campaign} onChange={onChange} />

            <CampaignSetupFrequencyCard campaign={campaign} onChange={onChange} />
        </Column>
    );
}
