// import { styled, tableCellClasses, tableRowClasses } from '@mui/material';
import {
    // Table,
    TableBody,
    // TableCell,
    // TableContainer,
    TableHead,
    TableRow,
    // Typography,
} from '@mui/material';

import { Campaign } from '../../../types/Campaign';
import { Table } from '../../../components/Table';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';
import { TableCell, TablePrimaryCell } from './CampaignWizard/CampaignWizardStyles';
// import Column from '../../../components/Column';

type CampaignSummaryCreativeTableProps = {
    campaign: Campaign;
};

export default function CampaignSummaryCreativeTable(props: CampaignSummaryCreativeTableProps) {
    const { campaign } = props;
    const { $campaignTypeCodes } = useCampaignWizardContext();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>Creative</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <TableRow>
                    <TablePrimaryCell>Creative Type</TablePrimaryCell>
                    <TableCell>{$campaignTypeCodes?.getCodeDescription(campaign.campaignType)}</TableCell>
                </TableRow>
                {campaign.campaignType === 'Static' && (
                    <>
                        {/*{files && files.length > 0 && (
                            <TableRow>
                                <TablePrimaryCell>Creative</TablePrimaryCell>
                                <TableCell>
                                    <Column gap={0.5}>
                                        {files.map((file: File) => {
                                            return (
                                                <Typography
                                                    key={file.name}
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    {file.name}
                                                </Typography>
                                            );
                                        })}
                                    </Column>
                                </TableCell>
                            </TableRow>
                        )}*/}

                        <TableRow>
                            <TablePrimaryCell>Destination URL</TablePrimaryCell>
                            <TableCell>{campaign.landingPage ?? 'Not set'}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TablePrimaryCell>Call to Actions</TablePrimaryCell>
                            <TableCell>{campaign.callToActions ?? 'Not set'}</TableCell>
                        </TableRow>
                    </>
                )}
                {campaign.campaignType === 'AdTag' && (
                    <>
                        <TableRow>
                            <TablePrimaryCell>Ad Tag URL</TablePrimaryCell>
                            <TableCell>{campaign.adTagUrl ?? 'Not set'}</TableCell>
                        </TableRow>
                    </>
                )}
            </TableBody>
        </Table>
    );
}
