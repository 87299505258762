import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Agency } from '../../types/Agency';
import { ChangeEvent, useCallback } from 'react';

export type AgencyUpdateCallback = (name: string, changes: Partial<Agency>) => void;

export type AgencyGatewayAPIProps = {
    agency: Agency;
    onAgencyUpdate: AgencyUpdateCallback;
};

const GATEWAY_API: { [key: string]: number } = {
    GET_ENABLED: 1, // 01 in binary
    POST_ENABLED: 2, // 10 in binary
};

export default function AgencyGatewayAPI({ agency, onAgencyUpdate }: AgencyGatewayAPIProps) {
    const handleFieldChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const enabled = GATEWAY_API[event.target.name];
            onAgencyUpdate('gatewayAmazonDSPIsActive', {
                gatewayAmazonDSPIsActive: event.target.checked
                    ? agency.gatewayAmazonDSPIsActive | enabled
                    : agency.gatewayAmazonDSPIsActive & ~enabled,
            });
        },
        [onAgencyUpdate]
    );

    return (
        <section>
            <Typography variant="h6" gutterBottom>
                Gateway API - Amazon DSP
            </Typography>
            <Grid container spacing={2}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <>
                                <Switch
                                    checked={
                                        (agency.gatewayAmazonDSPIsActive & GATEWAY_API.GET_ENABLED) ===
                                        GATEWAY_API.GET_ENABLED
                                    }
                                    onChange={handleFieldChange}
                                    name="GET_ENABLED"
                                />
                            </>
                        }
                        label="GET"
                    />
                </Grid>

                <Grid item>
                    <FormControlLabel
                        control={
                            <>
                                <Switch
                                    checked={
                                        (agency.gatewayAmazonDSPIsActive & GATEWAY_API.POST_ENABLED) ===
                                        GATEWAY_API.POST_ENABLED
                                    }
                                    onChange={handleFieldChange}
                                    name="POST_ENABLED"
                                />
                            </>
                        }
                        label="POST"
                    />
                </Grid>
            </Grid>
        </section>
    );
}
