import { useEffect, useMemo, useState } from 'react';
import { Grid, RadioGroup, SvgIconProps } from '@mui/material';
import { AttachMoney as AttachMoneyIcon, CrisisAlert as CrisisAlertIcon } from '@mui/icons-material';

import { Code } from '../../../types/Code';
import { RadioCard, RadioCardGroupProps } from '../../../components/RadioCard';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';

type CampaignBidStrategyRadioGroupProps = {
    filter?(codes: Code[]): Code[];
} & RadioCardGroupProps;

export default function CampaignBidStrategyRadioGroup(props: CampaignBidStrategyRadioGroupProps) {
    const { disabled, filter, ...radioGroupProps } = props;
    const { $bidStrategyCodes } = useCampaignWizardContext();
    const { codes = [], defaultCode = null, fetchCodes = () => {} } = $bidStrategyCodes || {};

    const [filteredCodes, setFilteredCodes] = useState<Code[]>([]);

    const defaultValue = useMemo(() => {
        return defaultCode ? defaultCode.code : '';
    }, [defaultCode]);

    useEffect(() => {
        fetchCodes();
    }, [fetchCodes]);

    useEffect(() => {
        if (codes && codes.length > 0) {
            setFilteredCodes(filter ? filter(codes) : codes);
        } else {
            setFilteredCodes([]);
        }
    }, [codes, filter]);

    const getCodeIcon = (code: Code): React.ComponentType<SvgIconProps> | undefined => {
        switch (code.code) {
            case 'SPEND_BUDGET_IN_FULL':
                return CrisisAlertIcon;

            case 'PRIORITIZE_KPI_TARGET':
                return AttachMoneyIcon;
        }

        return undefined;
    };

    const getCodeLabel = (code: Code): string => {
        switch (code.code) {
            case 'SPEND_BUDGET_IN_FULL':
                return 'Spend Budget in Full';

            case 'PRIORITIZE_KPI_TARGET':
                return 'Prioritize KPI Target';
        }

        return code.description;
    };

    const getCodeDescription = (code: Code): string => {
        switch (code.code) {
            case 'SPEND_BUDGET_IN_FULL':
                return 'Prioritize spending full budget, while maximizing performance.';

            case 'PRIORITIZE_KPI_TARGET':
                return 'Prioritize spending based on KPI Target, while maximizing performance.';
        }

        return code.description;
    };

    return (
        <RadioGroup defaultValue={defaultValue} {...radioGroupProps} value={props.value}>
            {filteredCodes && (
                <Grid container spacing={3}>
                    {filteredCodes.map((code: Code) => {
                        return (
                            <Grid key={code.code} item xs={6}>
                                <RadioCard
                                    direction="row"
                                    required={true}
                                    disabled={disabled}
                                    value={code.code}
                                    description={getCodeDescription(code)}
                                    label={getCodeLabel(code)}
                                    LabelIcon={getCodeIcon(code)}
                                    CardProps={{
                                        sx: {
                                            '& .MuiCardContent-root': {
                                                minHeight: 110,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </RadioGroup>
    );
}
