import { useState } from 'react';

import { AudienceSource } from '../types/AudienceSource';
import { AudienceSourceCategory, AudienceSourceCategoryPairGroups } from '../types/AudienceSourceCategory';

export function parseRawAudienceSourceCategoryPairs(categories: string[]): AudienceSourceCategoryPairGroups {
    // const groups: { [parent: string]: string[]; } = {};
    const groups: AudienceSourceCategoryPairGroups = {};

    categories.forEach((category: string) => {
        const parts = category.split('|');
        const parent = parts[0];
        const subCategory = parts[1];

        if (!groups[parent]) {
            groups[parent] = [];
        }

        if (subCategory) {
            groups[parent].push(subCategory);
        }
    });

    return groups;
}

export interface AudienceSourceCategories {
    // API
    audienceSourceCategories: AudienceSourceCategory[];
    setAudienceSourceCategories: (audienceSourceCategories: AudienceSourceCategory[]) => void;

    // Utilities
    identifyAudienceSourceCategories: (audienceSources: AudienceSource[]) => AudienceSourceCategory[];
    hasAudienceSourceCategory: (target: AudienceSourceCategory) => boolean;
    hasAudienceSourceCategoryCode: (targetCode: string) => boolean;
    getAudienceSourceCategory: (audienceSourceCategoryCode: string) => AudienceSourceCategory | undefined;
    toAudienceSourceCategoryCode: (audienceSourceCategory: AudienceSourceCategory) => string;
    toAudienceSourceCategoryCodes: (audienceSourceCategories: AudienceSourceCategory[]) => string[];
}

export function useAudienceSourceCategories(): AudienceSourceCategories {
    const [audienceSourceCategories, setAudienceSourceCategories] = useState<AudienceSourceCategory[]>([]);

    // API

    const getAudienceSourceCategory = (audienceSourceCategoryCode: string): AudienceSourceCategory | undefined => {
        let audienceSourceCategory: AudienceSourceCategory | undefined;

        for (audienceSourceCategory of audienceSourceCategories) {
            if (audienceSourceCategory.code === audienceSourceCategoryCode) {
                return audienceSourceCategory;
            }

            for (audienceSourceCategory of audienceSourceCategory.children) {
                if (audienceSourceCategory.code === audienceSourceCategoryCode) {
                    return audienceSourceCategory;
                }
            }
        }

        return audienceSourceCategory;
    };

    const hasAudienceSourceCategory = (target: AudienceSourceCategory): boolean => {
        const audienceSourceCategory = getAudienceSourceCategory(target.code);
        return audienceSourceCategory !== undefined;
    };

    const hasAudienceSourceCategoryCode = (targetCode: string): boolean => {
        const audienceSourceCategory = getAudienceSourceCategory(targetCode);
        return audienceSourceCategory !== undefined;
    };

    // Utilities

    const identifyAudienceSourceCategories = (audienceSources: AudienceSource[]): AudienceSourceCategory[] => {
        const mapping: Map<string, AudienceSourceCategory> = new Map();

        audienceSources.forEach((audienceSource: AudienceSource) => {
            const categoryName: string = audienceSource.category ?? '';
            const subCategoryName: string = audienceSource.subCategory ?? '';

            // Ensure category exists in the map
            if (!mapping.has(categoryName)) {
                mapping.set(categoryName, new AudienceSourceCategory(categoryName, categoryName, categoryName));
            }

            const category = mapping.get(categoryName);

            // Ensure subcategory exists in the category's children
            if (category && subCategoryName) {
                let subCategory = category.children.find((child) => child.name === subCategoryName);

                if (!subCategory) {
                    subCategory = new AudienceSourceCategory(
                        subCategoryName,
                        subCategoryName,
                        `${category.code}|${subCategoryName}`
                    );
                    category.children.push(subCategory);
                }
            }
        });

        return Array.from(mapping.values());
    };

    const toAudienceSourceCategoryCode = (audienceSourceCategory: AudienceSourceCategory): string => {
        return audienceSourceCategory.code;
    };

    const toAudienceSourceCategoryCodes = (audienceSourceCategories: AudienceSourceCategory[]): string[] => {
        return audienceSourceCategories.flatMap((audienceSourceCategory: AudienceSourceCategory) => {
            const audienceSourceCategoryCodes: string[] = [toAudienceSourceCategoryCode(audienceSourceCategory)];

            if (audienceSourceCategory.children) {
                audienceSourceCategoryCodes.push(...audienceSourceCategory.children.map(toAudienceSourceCategoryCode));
            }

            return audienceSourceCategoryCodes;
        });
    };

    return {
        audienceSourceCategories,
        setAudienceSourceCategories,

        // Utilities
        identifyAudienceSourceCategories,
        hasAudienceSourceCategory,
        hasAudienceSourceCategoryCode,
        getAudienceSourceCategory,
        toAudienceSourceCategoryCode,
        toAudienceSourceCategoryCodes,
    };
}
