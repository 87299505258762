import { useState } from 'react';

export interface AppLoaderState {
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

export function useAppLoader(): AppLoaderState {
    const [loading, setLoading] = useState<boolean>(false);

    return { loading, setLoading };
}
