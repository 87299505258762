import { useEffect, useRef, useState } from 'react';

import { ZipCode } from '../types/Location';

export const toUniqueZipCodes = (zipCodes: ZipCode[]): ZipCode[] => {
    const uniqueCodes = new Set<string>();

    return zipCodes.filter((zipCode: ZipCode) => {
        if (!uniqueCodes.has(zipCode.code)) {
            uniqueCodes.add(zipCode.code);
            return true;
        }

        return false;
    });

    // return zipCodes
    //     .filter(
    //         (zipCode: ZipCode, index: number) => zipCodes.indexOf(zipCode) === index
    //     );
};

export const parseZipCodes = (rawZipCodes: string): ZipCode[] => {
    const zipCodes: ZipCode[] = [];

    if (rawZipCodes) {
        if (rawZipCodes.includes(',')) {
            zipCodes.push(
                ...rawZipCodes.split(',').map((zipCode: string) => ({
                    code: zipCode.trim(),
                    population: 0,
                }))
            );
        } else {
            zipCodes.push(
                ...rawZipCodes.split('\n').map((zipCode: string) => ({
                    code: zipCode.trim(),
                    population: 0,
                }))
            );
        }
    }

    return toUniqueZipCodes(zipCodes);
};

export interface ZipCodes {
    zipCodes: ZipCode[];
    setZipCodes: (zipCodes: ZipCode[]) => void;
    attachZipCodes: (attachedZipCodes: ZipCode[]) => void;
    attachZipCode: (attachedZipCode: ZipCode) => void;
    detachZipCodes: (detachedZipCodes: ZipCode[]) => void;
    detachZipCode: (detachedZipCode: ZipCode) => void;
    resetZipCodes: () => void;

    // Raw
    setRawZipCodes: (rawZipCodes: string) => void;
    toRawZipCodes: () => string;

    // Utility
    hasZipCode: (zipCode: ZipCode) => boolean;
    isZipCode: (targetZipCode: ZipCode, zipCode: ZipCode) => boolean;
    getZipCodeKey: (zipCode: ZipCode) => string;
}

export type ZipCodesProps = {
    delimeter?: string;
};

export default function useZipCodes(props: ZipCodesProps = {}): ZipCodes {
    const { delimeter = ',' } = props;

    const [zipCodes, setZipCodes] = useState<ZipCode[]>([]);
    const $zipCodes = useRef<ZipCode[]>([]);

    useEffect(() => {
        $zipCodes.current = zipCodes;
    }, [zipCodes]);

    const attachZipCodes = (attachedZipCodes: ZipCode[]): void => {
        $zipCodes.current = toUniqueZipCodes([...$zipCodes.current, ...attachedZipCodes]);
        setZipCodes($zipCodes.current);
    };

    const attachZipCode = (attachedZipCode: ZipCode): void => {
        attachZipCodes([attachedZipCode]);
    };

    const detachZipCodes = (detachedZipCodes: ZipCode[]): void => {
        const detachedZipCodeCodes: string[] = detachedZipCodes.map((zipCode: ZipCode) => zipCode.code);

        $zipCodes.current = $zipCodes.current.filter((zipCode: ZipCode) => {
            if (detachedZipCodeCodes.includes(zipCode.code)) {
                return false;
            }

            return true;
        });

        setZipCodes($zipCodes.current);
    };

    const detachZipCode = (detachedZipCode: ZipCode): void => {
        detachZipCodes([detachedZipCode]);
    };

    const resetZipCodes = (): void => {
        $zipCodes.current = [];
        setZipCodes($zipCodes.current);
    };

    // Raw

    const setRawZipCodes = (rawZipCodes: string): void => {
        $zipCodes.current = parseZipCodes(rawZipCodes);
        setZipCodes($zipCodes.current);
    };

    const toRawZipCodes = (): string => {
        return $zipCodes.current
            .map((zipCode: ZipCode) => getZipCodeKey(zipCode))
            .filter((zipCode: string) => typeof zipCode === 'string' && zipCode.length > 0)
            .join(delimeter);
    };

    // Utility

    const findZipCodeIndex = (targetZipCode: ZipCode): number => {
        return $zipCodes.current.findIndex((zipCode: ZipCode) => zipCode.code === targetZipCode.code);
    };

    const hasZipCode = (zipCode: ZipCode): boolean => {
        return findZipCodeIndex(zipCode) > -1;
    };

    const isZipCode = (targetZipCode: ZipCode, zipCode: ZipCode): boolean => {
        return zipCode.code === targetZipCode.code;
    };

    const getZipCodeKey = (zipCode: ZipCode): string => {
        return zipCode?.code ?? '';
    };

    return {
        zipCodes,
        setZipCodes,
        attachZipCodes,
        attachZipCode,
        detachZipCodes,
        detachZipCode,
        resetZipCodes,

        // Raw
        setRawZipCodes,
        toRawZipCodes,

        // Utility
        hasZipCode,
        isZipCode,
        getZipCodeKey,
    };
}
