import { useEffect, useMemo } from 'react';
import { Grid, RadioGroup, SvgIconProps } from '@mui/material';
import {
    InsertPhotoOutlined as InsertPhotoOutlinedIcon,
    LiveTv as LiveTvIcon,
    MusicNoteOutlined as MusicNoteOutlinedIcon,
    OndemandVideo as OndemandVideoIcon,
} from '@mui/icons-material';

import { Code } from '../../../types/Code';
import { RadioCard, RadioCardGroupProps } from '../../../components/RadioCard';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';

type CampaignMediaTypeRadioGroupProps = {} & RadioCardGroupProps;

export default function CampaignMediaTypeRadioGroup(props: CampaignMediaTypeRadioGroupProps) {
    const { disabled } = props;
    const { $mediaTypeCodes } = useCampaignWizardContext();
    const { codes = [], defaultCode = null, fetchCodes = () => {} } = $mediaTypeCodes || {};

    const defaultValue = useMemo(() => {
        return defaultCode ? defaultCode.code : '';
    }, [defaultCode]);

    useEffect(() => {
        fetchCodes();
    }, [fetchCodes]);

    const getCodeIcon = (code: Code): React.ComponentType<SvgIconProps> | undefined => {
        switch (code.code) {
            case 'Display':
                return InsertPhotoOutlinedIcon;

            case 'Online Video':
            case 'Video':
                return OndemandVideoIcon;

            case 'Streaming TV':
            case 'TV':
                return LiveTvIcon;

            case 'Audio':
                return MusicNoteOutlinedIcon;
        }

        return undefined;
    };

    return (
        <RadioGroup defaultValue={defaultValue} {...props} value={props.value}>
            {codes && (
                <Grid container spacing={3}>
                    {codes.map((code: Code) => {
                        return (
                            <Grid key={code.code} item xs={3}>
                                <RadioCard
                                    direction="column"
                                    required={true}
                                    disabled={disabled}
                                    value={code.code}
                                    label={code.description}
                                    LabelIcon={getCodeIcon(code)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </RadioGroup>
    );
}
