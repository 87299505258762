import { useEffect, useRef, useState } from 'react';
import '../index.css';

export type FlyingAstronautMascotProps = {
    onAnimationEnd?: (event: Event) => void;
};

export function FlyingAstronautMascot(props: FlyingAstronautMascotProps) {
    const { onAnimationEnd } = props;
    const $mascot = useRef<HTMLDivElement>(null);
    const [animationEnded, setAnimationEnded] = useState<boolean>(false);

    const handleAnimationEnd = (event: Event) => {
        if (onAnimationEnd) {
            onAnimationEnd(event);
        }

        setAnimationEnded(true);
    };

    useEffect(() => {
        if ($mascot.current) {
            $mascot.current.addEventListener('animationend', handleAnimationEnd);
        }

        return () => {
            if ($mascot.current) {
                $mascot.current.removeEventListener('animationend', handleAnimationEnd);
            }
        };
    }, []);

    return (
        <div className="Flying-Astronaut-Mascot-space" style={{ zIndex: animationEnded ? 0 : 10000 }}>
            <div ref={$mascot} className="Flying-Astronaut-Mascot-spaceship">
                <img src="/Mascot_Astronaut_Flying.png" style={{ height: 256 }} />
            </div>
        </div>
    );
}
