import { Chip } from '@mui/material';
import { CampaignStatus } from '../../types/Campaign';
import { UserContext } from '../../App';
import { useContext } from 'react';
import theme from '../../theme';

export default function CampaignStatusComponent(props: { deliveryStatus: CampaignStatus }) {
    const { deliveryStatus } = props;
    const { userContext } = useContext(UserContext);
    let color: 'success' | 'warning' | 'error' | 'default';
    switch (deliveryStatus.dspStatusId) {
        case 'DRAFT':
            color = 'warning';
            break;

        case 'PENDING_CLIENT':
        case 'PENDING_COGNITION':
            color = 'warning';
            break;

        case 'LINEITEMS_NOT_RUNNING':
            if (userContext.isAdmin()) {
                color = 'error';
            } else {
                color = 'success';
            }
            break;

        case 'DELIVERING':
        case 'READY_TO_DELIVER':
            color = 'success';
            break;

        case 'OUT_OF_BUDGET':
            color = 'error';
            break;

        default:
            color = 'default';
            break;
    }
    return (
        <>
            <Chip
                size="small"
                label={userContext.isAdmin() ? deliveryStatus.internalStatus : deliveryStatus.externalStatus}
                variant="filled"
                sx={{
                    fontWeight: theme.typography.fontWeightMedium,
                }}
                color={color}
            />
        </>
    );
}
