import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Dealer } from '../../types/Dealer';
import { ChangeEvent, useCallback } from 'react';

export type DealerUpdateCallback = (name: string, changes: Partial<Dealer>) => void;

export type DealerGatewayAPIProps = {
    dealer: Dealer;
    onDealerUpdate: DealerUpdateCallback;
};

const GATEWAY_API: { [key: string]: number } = {
    GET_ENABLED: 1, // 01 in binary
    POST_ENABLED: 2, // 10 in binary
};

export default function DealerGatewayAPI({ dealer, onDealerUpdate }: DealerGatewayAPIProps) {
    const handleFieldChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const enabled = GATEWAY_API[event.target.name];
            onDealerUpdate('gatewayAmazonDSPIsActive', {
                gatewayAmazonDSPIsActive: event.target.checked
                    ? dealer.gatewayAmazonDSPIsActive | enabled
                    : dealer.gatewayAmazonDSPIsActive & ~enabled,
            });
        },
        [onDealerUpdate]
    );

    return (
        <section>
            <Typography variant="h6" gutterBottom>
                Gateway API - Amazon DSP
            </Typography>
            <Grid container spacing={2}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <>
                                <Switch
                                    checked={
                                        (dealer.gatewayAmazonDSPIsActive & GATEWAY_API.GET_ENABLED) ===
                                        GATEWAY_API.GET_ENABLED
                                    }
                                    onChange={handleFieldChange}
                                    name="GET_ENABLED"
                                />
                            </>
                        }
                        label="GET"
                    />
                </Grid>

                <Grid item>
                    <FormControlLabel
                        control={
                            <>
                                <Switch
                                    checked={
                                        (dealer.gatewayAmazonDSPIsActive & GATEWAY_API.POST_ENABLED) ===
                                        GATEWAY_API.POST_ENABLED
                                    }
                                    onChange={handleFieldChange}
                                    name="POST_ENABLED"
                                />
                            </>
                        }
                        label="POST"
                    />
                </Grid>
            </Grid>
        </section>
    );
}
