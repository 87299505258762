import { ChangeEvent } from 'react';
import { FormControl, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Card, CardContent } from './CampaignWizardStyles';
import { makeStyles } from '@mui/styles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDTextField } from '../../../../components/CDTextField';
import ActiveStatus from '../../../../components/ActiveStatus';
import Column from '../../../../components/Column';

type CampaignReviewCampaignNameCardProps = {} & CampaignWizardStepProps;

export default function CampaignReviewCampaignNameCard(props: CampaignReviewCampaignNameCardProps) {
    const { campaign, onChange } = props;

    const useStyles = makeStyles({
        section: {
            padding: '15px',
            borderRadius: '6px',
            border: '1px solid #e0e0e0',
            position: 'relative',
            '& h6': {
                color: '#777',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '13px',
                position: 'absolute',
                top: '-11px',
                background: '#fff',
                left: '6px',
                padding: '0 5px',
                backgroundColor: '#fff',
            },
        },
    });
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            switch (event.target.name) {
                case 'gatewayAmazonDSPIsActive':
                    onChange({
                        [event.target.name]: event.target.checked ? 2 : 0,
                    });
                    break;

                default:
                    onChange({
                        [event.target.name]: event.target.value,
                    });
                    break;
            }
        }
    };

    return (
        <Card id="campaign-wizard-review-campaign-name" variant="outlined">
            <CardContent>
                <Column>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <FormControl fullWidth size="small">
                                <CDTextField
                                    variant="outlined"
                                    label="Campaign Name"
                                    name="campaignName"
                                    required={true}
                                    value={campaign.campaignName}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={8}>
                            <FormControl fullWidth size="small">
                                <CDTextField
                                    variant="outlined"
                                    label="Email for Approval"
                                    name="emailForApproval"
                                    required={false}
                                    value={campaign.emailForApproval}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={8}>
                            <FormControl fullWidth size="small">
                                <CDTextField
                                    variant="outlined"
                                    label="Inventory Provider"
                                    name="inventoryProvider"
                                    required={false}
                                    value={campaign.inventoryProvider}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={8}>
                            <FormControl fullWidth size="small">
                                <CDTextField
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    label="Additional Comments"
                                    name="additionalComments"
                                    required={false}
                                    value={campaign.additionalComments}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>

                        {campaign.deliveryStatusId === 'DRAFT' && (
                            <Grid item xs={8}>
                                <section className={classes.section}>
                                    <Typography variant="h6">Gateway API - Amazon DSP</Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={campaign.gatewayAmazonDSPIsActive === 2}
                                                name="gatewayAmazonDSPIsActive"
                                                onChange={handleChange}
                                            />
                                        }
                                        label={
                                            <ActiveStatus
                                                activeStatus={campaign.gatewayAmazonDSPIsActive === 2}
                                                activeText="Create campaign in DSP"
                                                inactiveText="Do not create campaign in DSP"
                                            />
                                        }
                                    />
                                </section>
                            </Grid>
                        )}
                    </Grid>
                </Column>
            </CardContent>
        </Card>
    );
}
