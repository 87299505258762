import { useCallback, useEffect, useState } from 'react';

import { InventorySource, InventorySourceType } from '../types/Inventory';

export interface InventorySourceTypes {
    fetchInventorySourceTypes: () => void;
    inventorySourceTypes: InventorySourceType[];
    setInventorySourceTypes: (inventorySourceTypes: InventorySourceType[]) => void;
    hasInventorySourceType: (targetInventorySourceType: InventorySourceType) => boolean;
    hasInventorySourceTypeCode: (targetInventorySourceTypeCode: string) => boolean;
    getInventorySourceType: (inventorySourceTypeCode: string) => InventorySourceType | undefined;

    // Utilities
    identifyInventorySourceTypes: (inventorySources: InventorySource[]) => InventorySourceType[];
    toInventorySourceTypeCode: (inventorySourceType: InventorySourceType) => string;
    toInventorySourceTypeCodes: (uncovertedInventorySourceTypes: InventorySourceType[]) => string[];
    toInventorySourceTypeName: (inventorySourceType: InventorySourceType) => string;
    toInventorySourceTypeNames: (uncovertedInventorySourceTypes: InventorySourceType[]) => string[];
}

export function useInventorySourceTypes(): InventorySourceTypes {
    const [inventorySourceTypes, setInventorySourceTypes] = useState<InventorySourceType[]>([]);
    const _inventorySourceTypes: InventorySourceType[] = [
        { code: 'AMAZON', name: 'Amazon' },
        { code: 'APD', name: 'Amazon Publisher' },
        { code: 'THIRD_PARTY_EXCHANGE', name: 'Third-Party Exchanges' },
        { code: 'DEAL', name: 'Deals' },
    ];

    // Internal

    const _getInventorySourceType = (inventorySourceTypeCode: string): InventorySourceType | undefined => {
        return _inventorySourceTypes.find(
            (inventorySourceType: InventorySourceType) => inventorySourceType.code === inventorySourceTypeCode
        );
    };

    // API

    const fetchInventorySourceTypes = useCallback(() => {
        setInventorySourceTypes(_inventorySourceTypes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasInventorySourceType = (targetInventorySourceType: InventorySourceType): boolean => {
        const inventorySourceType = getInventorySourceType(targetInventorySourceType.code);
        return inventorySourceType !== undefined;
    };

    const hasInventorySourceTypeCode = (targetInventorySourceTypeCode: string): boolean => {
        const inventorySourceType = getInventorySourceType(targetInventorySourceTypeCode);
        return inventorySourceType !== undefined;
    };

    const toInventorySourceTypeCode = (inventorySourceType: InventorySourceType): string => {
        return inventorySourceType.code;
    };

    const toInventorySourceTypeCodes = (uncovertedInventorySourceTypes: InventorySourceType[]): string[] => {
        return uncovertedInventorySourceTypes.map(toInventorySourceTypeCode);
    };

    const toInventorySourceTypeName = (inventorySourceType: InventorySourceType): string => {
        return inventorySourceType.name;
    };

    const toInventorySourceTypeNames = (uncovertedInventorySourceTypes: InventorySourceType[]): string[] => {
        return uncovertedInventorySourceTypes.map(toInventorySourceTypeName);
    };

    const getInventorySourceType = (inventorySourceTypeCode: string): InventorySourceType | undefined => {
        return inventorySourceTypes.find(
            (inventorySourceType: InventorySourceType) => inventorySourceType.code === inventorySourceTypeCode
        );
    };

    const identifyInventorySourceTypes = (inventorySources: InventorySource[]): InventorySourceType[] => {
        return inventorySources
            .map((inventorySource: InventorySource) => {
                return inventorySource.inventorySourceType;
            })
            .filter((value: string, index: number, self: string[]) => {
                return self.indexOf(value) === index;
            })
            .map((inventorySourceTypeCode: string) => {
                let inventorySourceType: InventorySourceType | undefined =
                    _getInventorySourceType(inventorySourceTypeCode);
                return inventorySourceType ?? { code: '', name: '' };
            })
            .filter((inventorySourceType: InventorySourceType) => {
                return inventorySourceType.code.length > 0;
            });
    };

    useEffect(() => {
        fetchInventorySourceTypes();
    }, [fetchInventorySourceTypes]);

    return {
        fetchInventorySourceTypes,
        inventorySourceTypes,
        setInventorySourceTypes,
        hasInventorySourceType,
        hasInventorySourceTypeCode,
        getInventorySourceType,

        // Utilities
        identifyInventorySourceTypes,
        toInventorySourceTypeCode,
        toInventorySourceTypeCodes,
        toInventorySourceTypeName,
        toInventorySourceTypeNames,
    };
}
