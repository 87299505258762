import { styled } from '@mui/material/styles';
import { AppBar, AppBarProps } from '@mui/material';

export const StickyBottomAppBar = styled((props: AppBarProps) => (
    <AppBar component="footer" position="sticky" color="transparent" {...props} />
))(({ theme }) => ({
    '&': {
        backgroundColor: theme.palette.common.white,
        borderTopWidth: 1,
        borderTopColor: theme.palette.divider,
        borderTopStyle: 'solid',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        bottom: 0,
        left: 0,
        right: 0,
    },
}));
