import { useState } from 'react';

import { Campaign } from '../../../types/Campaign';
import ConfirmDialog, { ConfirmDialogProps } from '../../../components/ConfirmDialog';
import CDButton from '../../../components/CDButton';
// import ApiService from '../../../ApiService';

// import { useInfoMessage } from '../../../hooks/useInfoMessage';

type CampaignModifyConfirmButtonProps = {
    campaign: Campaign;
    onConfirm?: (campaign: Campaign) => void;
    onCancel?: (campaign: Campaign) => void;
};

// { campaign, onConfirm }: CampaignModifyConfirmButtonProps
export default function CampaignModifyConfirmButton(props: CampaignModifyConfirmButtonProps) {
    const { campaign } = props;
    // const { showInfoMessage } = useInfoMessage();

    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const handleShowConfirmDialog = () => {
        showConfirm();
    };

    const handleConfirm = () => {
        hideConfirm();

        if (props.onConfirm) {
            props.onConfirm(campaign);
        }
    };

    const handleCancel = () => {
        hideConfirm();

        if (props.onCancel) {
            props.onCancel(campaign);
        }
    };

    const showConfirm = () => {
        setConfirmDialogProps({
            title: 'Confirm Modification',
            text: 'The new campaign you have started will not be saved. Do you still want to proceed?',
            onOk: () => handleConfirm(),
            okText: 'Proceed To Modify',
            okColor: 'primary',
            onCancel: () => handleCancel(),
            cancelColor: 'primary',
            cancelIcon: null,
        });
    };

    const hideConfirm = () => {
        setConfirmDialogProps(null);
    };

    return (
        <>
            <CDButton variant="contained" color="primary" onClick={handleShowConfirmDialog}>
                Modify This Campaign
            </CDButton>

            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        cancelIcon={confirmDialogProps.cancelIcon}
                        cancelColor={confirmDialogProps.cancelColor}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        okColor={confirmDialogProps.okColor}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                    />
                </>
            )}
        </>
    );
}
