import { useEffect, useMemo, useState } from 'react';
import { Grid, RadioGroup, SvgIconProps } from '@mui/material';
import {
    BuildOutlined as BuildOutlinedIcon,
    CarCrashOutlined as CarCrashOutlinedIcon, // @TODO Incorrect - Car Tag
    DirectionsCarFilledOutlined as DirectionsCarFilledOutlinedIcon,
    CampaignOutlined as CampaignOutlinedIcon, // @TODO Incorrect
} from '@mui/icons-material';

import { Code } from '../../../types/Code';
import { RadioCard, RadioCardGroupProps } from '../../../components/RadioCard';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';

type CampaignStrategyTypeRadioGroupProps = {
    filter?(codes: Code[]): Code[];
} & RadioCardGroupProps;

export default function CampaignStrategyTypeRadioGroup(props: CampaignStrategyTypeRadioGroupProps) {
    const { disabled, filter, ...radioGroupProps } = props;
    const { $strategyTypeCodes } = useCampaignWizardContext();
    const { codes = [], defaultCode = null, fetchCodes = () => {} } = $strategyTypeCodes || {};

    const [filteredCodes, setFilteredCodes] = useState<Code[]>([]);

    const defaultValue = useMemo(() => {
        return defaultCode ? defaultCode.code : '';
    }, [defaultCode]);

    useEffect(() => {
        fetchCodes();
    }, [fetchCodes]);

    useEffect(() => {
        if (codes && codes.length > 0) {
            setFilteredCodes(filter ? filter(codes) : codes);
        } else {
            setFilteredCodes([]);
        }
    }, [codes, filter]);

    const getCodeIcon = (code: Code): React.ComponentType<SvgIconProps> | undefined => {
        switch (code.code) {
            case 'Acquisition':
                return CarCrashOutlinedIcon;

            case 'Awareness':
                return CampaignOutlinedIcon;

            case 'Inventory':
                return DirectionsCarFilledOutlinedIcon;

            case 'FixedOps':
            case 'Parts':
                return BuildOutlinedIcon;
        }

        return undefined;
    };

    const getCodeLabel = (code: Code): string => {
        switch (code.code) {
            case 'Acquisition':
                return 'Acquisition';

            case 'Awareness':
                return 'Brand Awareness';

            case 'Inventory':
                return 'Inventory';

            case 'FixedOps':
                return 'Service';

            case 'Parts':
                return 'Parts';
        }

        return code.description;
    };

    return (
        <RadioGroup defaultValue={defaultValue} {...radioGroupProps} value={props.value}>
            {filteredCodes && (
                <Grid container spacing={3}>
                    {filteredCodes.map((code: Code) => {
                        return (
                            <Grid key={code.code} item xs={3}>
                                <RadioCard
                                    direction="column"
                                    required={true}
                                    disabled={disabled}
                                    value={code.code}
                                    label={getCodeLabel(code)}
                                    LabelIcon={getCodeIcon(code)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </RadioGroup>
    );
}
