import { AudienceSource } from './AudienceSource';

export class AudienceSourceCategory {
    id: string | null = '';
    code: string = '';
    name: string = '';
    children: AudienceSourceCategory[] = [];

    constructor(id: string = '', name: string = '', code: string = '') {
        this.id = id;
        this.name = name;
        this.code = code;
    }
}

// Normalized value: `${category}|${subCategory}`
// Example value: 'In-market|Household & Grocery'
export interface AudienceSourceCategoryPair {
    category: string | null;
    subCategory: string | null;
}

export interface AudienceSourceCategoryPairGroups {
    [parent: string]: string[];
}

export class AudienceSourceCategoryGroup {
    category: string = '';
    audienceSources: AudienceSource[] = [];
}
