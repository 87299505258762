import { useEffect, useState } from 'react';

import { Campaign } from '../../../types/Campaign';
// import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';
import ApiService from '../../../ApiService';
import CampaignDuplicateDialog from './CampaignDuplicateDialog';
import CampaignSummaryDialog from './CampaignSummaryDialog';
import Utils from '../../../components/Utils';

type CampaignDuplicateValidatorProps = {
    campaign: Campaign;
    ignoreDuplicates?: boolean;
    onIgnoreDuplicates?: () => void;
};

export default function CampaignDuplicateValidator(props: CampaignDuplicateValidatorProps) {
    const { campaign, ignoreDuplicates } = props;

    // // Audience Sources
    // const { $audienceSources } = useCampaignWizardContext();
    // const { fetchAudienceSources = undefined } = $audienceSources || {};

    // // Locations
    // const { $locations } = useCampaignWizardContext();
    // const { fetchLocations = undefined } = $locations || {};

    const [duplicateCampaigns, setDuplicateCampaigns] = useState<Campaign[]>([]);
    const [duplicateCampaignDialogOpen, setDuplicateCampaignDialogOpen] = useState<boolean>(false);

    const [viewCampaign, setViewCampaign] = useState<Campaign | null>(null);
    const [viewCampaignDialogOpen, setViewCampaignDialogOpen] = useState<boolean>(false);

    const handleDuplicateCampaignDialogClose = () => {
        setDuplicateCampaignDialogOpen(false);
    };

    const handleDuplicateCampaignDialogCancel = () => {
        setDuplicateCampaignDialogOpen(false);
    };

    const handleDuplicateCampaignDialogConfirm = () => {
        setDuplicateCampaignDialogOpen(false);

        if (props.onIgnoreDuplicates) {
            props.onIgnoreDuplicates();
        }
    };

    const handleDuplicateCampaignDialogView = (campaign: Campaign) => {
        setViewCampaignDialogOpen(true);
        setViewCampaign(campaign);
    };

    const handleViewCampaignDialogClose = () => {
        setViewCampaignDialogOpen(false);
        setViewCampaign(null);
    };

    // useEffect(() => {
    //     if (fetchAudienceSources) {
    //         fetchAudienceSources();
    //     }
    // }, [fetchAudienceSources]);

    // useEffect(() => {
    //     if (fetchLocations) {
    //         fetchLocations();
    //     }
    // }, [fetchLocations]);

    useEffect(() => {
        // Only check for duplicates when creating a new Campaign
        if (!ignoreDuplicates && !campaign.id && !campaign.campaignId && campaign.budget > 0) {
            // && campaign.campaignType
            if (
                campaign.dealerId &&
                campaign.mediaType &&
                campaign.strategyType &&
                campaign.startDate &&
                campaign.endDate
            ) {
                ApiService.findDuplicateCampaigns(
                    null,
                    campaign.dealerId,
                    campaign.mediaType,
                    campaign.campaignType ?? 'DCO', // @TODO
                    campaign.strategyType,
                    Utils.getESTStartDate(campaign.startDate),
                    Utils.getESTEndDate(campaign.endDate)
                ).then((response) => {
                    setDuplicateCampaigns(response.data);
                    // setShowError(false);

                    if (response.data && response.data.length > 0) {
                        setDuplicateCampaignDialogOpen(true);
                    }
                });
            }
        }
    }, [
        ignoreDuplicates,
        campaign.id,
        campaign.campaignId,
        campaign.dealerId,
        campaign.mediaType,
        campaign.campaignType,
        campaign.strategyType,
        campaign.budget,
        campaign.startDate,
        campaign.endDate,
    ]);

    return (
        <>
            {duplicateCampaigns.length > 0 && !ignoreDuplicates && (
                <CampaignDuplicateDialog
                    campaign={campaign}
                    duplicateCampaigns={duplicateCampaigns}
                    open={duplicateCampaignDialogOpen}
                    onClose={handleDuplicateCampaignDialogClose}
                    onCancel={handleDuplicateCampaignDialogCancel}
                    onConfirm={handleDuplicateCampaignDialogConfirm}
                    onView={handleDuplicateCampaignDialogView}
                />
            )}

            {viewCampaign && (
                <CampaignSummaryDialog
                    open={viewCampaignDialogOpen}
                    campaign={viewCampaign}
                    onClose={handleViewCampaignDialogClose}
                />
            )}
        </>
    );
}
