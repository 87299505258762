import { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { Box, Stack, StackProps, Typography, TypographyProps } from '@mui/material';

import CDButton, { CDButtonProps } from '../CDButton';
import CountChip from '../CountChip';
import Row from '../Row';

import { InventorySource, InventorySourceCriteria, InventorySourceTypeGroup } from '../../types/Inventory';
import { useInventorySourceTypes } from '../../hooks/useInventorySourceTypes';
import InventorySourceDrawer from './InventorySourceDrawer';

export const CountChipText = styled((props: TypographyProps) => (
    <Typography variant="body2" component="span" {...props} />
))(({ theme }) => ({
    '&': {
        fontSize: 13,
    },
}));

export type InventorySourceFieldProps = {
    searchCriteria?: InventorySourceCriteria;
    changeSearchCriteria?: (searchCriteria: InventorySourceCriteria) => void;
    selections?: InventorySource[];
    fetchSelections?: () => void;
    hydrateSelections?: (selections: InventorySource[]) => InventorySource[];
    onChange?: (items: InventorySource[]) => void;
    items?: InventorySource[];
    buttonProps?: CDButtonProps;
    stackProps?: StackProps;
};

export default function InventorySourceField(props: InventorySourceFieldProps) {
    const {
        searchCriteria = {
            searchText: '',
            mediaType: '',
            inventorySourceTypes: [],
            runPrimeVideoOnly: false,
        },
        changeSearchCriteria = (searchCriteria: InventorySourceCriteria) => null,
        items,
        onChange,
        selections = [],
        fetchSelections,
        hydrateSelections,
        buttonProps,
        stackProps,
    } = props;

    const $inventorySourceTypes = useInventorySourceTypes();

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const isHydrated = useMemo((): boolean => {
        if (items && items.length > 0) {
            return items[0].name.length > 0;
        }
        return false;
    }, [items]);

    const inventorySourceTypeGroups = useMemo(() => {
        const groups: InventorySourceTypeGroup[] = [];
        const group: { [key: string]: InventorySourceTypeGroup } = {};

        if (items) {
            items.forEach((item: InventorySource) => {
                if (item.inventorySourceType) {
                    if (!group[item.inventorySourceType]) {
                        group[item.inventorySourceType] = new InventorySourceTypeGroup();
                        group[item.inventorySourceType].inventorySourceType = item.inventorySourceType;
                        groups.push(group[item.inventorySourceType]);
                    }
                    group[item.inventorySourceType].inventorySources.push(item);
                }
            });
        }

        return groups;
    }, [items]);

    const handleDrawerChange = (items: InventorySource[]): void => {
        if (onChange) {
            onChange(items);
        }
    };

    const handleDrawerClose = useCallback(
        (items: InventorySource[], reason: string) => {
            setDrawerOpen(false);

            if (['save'].includes(reason)) {
                if (onChange) {
                    onChange(items);
                }
            }
        },
        [onChange]
    );

    return (
        <Stack gap={3} {...stackProps}>
            {isHydrated && inventorySourceTypeGroups.length > 0 && (
                <Box>
                    <Row useFlexGap flexWrap="wrap" gap={1} sx={{ width: '100%' }}>
                        {inventorySourceTypeGroups.map((inventorySourceTypeGroup: InventorySourceTypeGroup) => {
                            const inventorySourceTypeGroupName: string = $inventorySourceTypes.getInventorySourceType(
                                inventorySourceTypeGroup.inventorySourceType
                            )?.name as string;

                            return inventorySourceTypeGroup.inventorySources.map((inventorySource: InventorySource) => (
                                <CountChip
                                    key={inventorySource.id}
                                    variant="outlined"
                                    label={
                                        <>
                                            <CountChipText>{inventorySourceTypeGroupName}</CountChipText>
                                            <CountChipText color="text.secondary">{' > '}</CountChipText>
                                            <CountChipText>{inventorySource.name}</CountChipText>
                                        </>
                                    }
                                    // count={inventorySourceTypeGroup.inventorySources.length}
                                    hideCount={true}
                                />
                            ));
                        })}
                    </Row>
                </Box>
            )}

            {onChange && (
                <Box>
                    <CDButton variant="outlined" {...buttonProps} onClick={() => setDrawerOpen(true)}>
                        {inventorySourceTypeGroups.length > 0 ? 'Edit' : 'Select'}
                    </CDButton>
                </Box>
            )}

            {fetchSelections !== undefined && (
                <InventorySourceDrawer
                    searchCriteria={searchCriteria}
                    changeSearchCriteria={changeSearchCriteria}
                    selections={selections}
                    fetchSelections={fetchSelections}
                    hydrateSelections={hydrateSelections}
                    open={drawerOpen}
                    items={items}
                    onChange={handleDrawerChange}
                    onClose={handleDrawerClose}
                />
            )}
        </Stack>
    );
}
