import { useEffect, useMemo, useState } from 'react';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import {
    DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
    DownloadOutlined as DownloadOutlinedIcon,
    UploadFileOutlined as UploadFileOutlinedIcon,
} from '@mui/icons-material';

import { CampaignWizardStepProps } from './CampaignWizard';
import { MAX_FILE_SIZE } from '../../../../hooks/useCampaignWizard';
import ApiService from '../../../../ApiService';
import Column from '../../../../components/Column';
import MediaFileUpload from '../../../../components/MediaFileUpload';
import Utils from '../../../../components/Utils';

const formatFileSize = (sizeInBytes: number): string => {
    const mb = sizeInBytes / (1024 * 1024);
    if (mb > 1) {
        return `${mb.toFixed(1)}mb`;
    }

    const kb = sizeInBytes / 1024;
    if (kb > 1) {
        return `${Math.round(kb)}kb`;
    }

    return `${sizeInBytes}`;
};

function FileListItem(props: {
    f: File;
    complete?: boolean;
    removeFile: (f: File) => void;
    downloadFile: (f: string) => void;
    maxFileSize: number;
}) {
    const { f, complete, removeFile, downloadFile, maxFileSize } = props;

    return (
        <ListItem>
            <ListItemIcon sx={{ minWidth: 40 }}>
                <UploadFileOutlinedIcon color="primary" />
            </ListItemIcon>

            {!f.size && (
                <Button
                    sx={{ textDecoration: 'underline' }}
                    color="info"
                    onClick={() => downloadFile(f.name)}
                    startIcon={<DownloadOutlinedIcon />}
                >
                    {f.name}
                </Button>
            )}

            {f.size && (
                <ListItemText
                    primary={f.name}
                    primaryTypographyProps={{
                        fontWeight: (theme) => theme.typography.fontWeightMedium,
                    }}
                    secondary={
                        f.size > maxFileSize ? (
                            <Typography color="error">
                                The maximum file upload size is {maxFileSize / (1024 * 1024)} MB. Larger files such as
                                video clips should be delivered via a File Sharing tool like Dropbox.
                            </Typography>
                        ) : (
                            <>
                                {formatFileSize(f.size)}
                                {complete && (
                                    <>
                                        {' • '}
                                        <Typography color="success.light" component="span" variant="body2">
                                            Complete
                                        </Typography>
                                    </>
                                )}
                            </>
                        )
                    }
                    secondaryTypographyProps={{
                        variant: 'body2',
                    }}
                ></ListItemText>
            )}

            {f.size && (
                <IconButton onClick={() => removeFile(f)}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            )}
        </ListItem>
    );
}

type CampaignCreativeAssetsProps = {
    onFileChange?: (files: File[]) => void;
    readOnly?: boolean;
} & Omit<CampaignWizardStepProps, 'onChange'>;

export default function CampaignCreativeAssets(props: CampaignCreativeAssetsProps) {
    const { campaign, onFileChange, readOnly } = props;

    const [files, setFiles] = useState<File[]>([]);
    const [existingFiles, setExistingFiles] = useState<File[]>([]);

    const fileCount = useMemo((): number => {
        return files.length + existingFiles.length;
    }, [existingFiles, files]);

    useEffect(() => {
        if (campaign.campaignId) {
            getFileList(campaign.campaignId, campaign.changeRequestId);
        }
    }, [campaign]);

    function getFileList(id: number, changeRequestId?: number) {
        ApiService.getCampaignCreatives(id, changeRequestId)
            .then((response) => {
                let fileList: any = [];
                response.data.forEach((f) => {
                    fileList.push({ name: f });
                });
                setExistingFiles(fileList);
            })
            .catch();
    }

    function removeFile(file: File) {
        let d = files.filter((f) => f.name !== file.name);
        setFiles(d);

        if (onFileChange) {
            onFileChange(d);
        }
    }

    function downloadFile(file: string) {
        // @ts-ignore
        ApiService.getCampaignCreative(campaign.campaignId, file)
            .then((response) => {
                Utils.downloadFile(response, 'application/octetstream');
            })
            .catch(() => {});
    }

    function handleFileChange(event: any) {
        const fileList = [...files];
        fileList.push.apply(fileList, event.target.files);
        setFiles(fileList);

        if (onFileChange) {
            onFileChange(fileList);
        }
    }

    return (
        <Column>
            {!readOnly && (
                <>
                    {campaign.mediaType === 'Video' && (
                        <MediaFileUpload
                            onChange={handleFileChange}
                            inputProps={{
                                accept: ['video/mp4'].join(', '),
                                multiple: true,
                            }}
                            children={
                                <Typography variant="body2" color="text.secondary">
                                    MP4
                                </Typography>
                            }
                        />
                    )}
                    {campaign.mediaType === 'Display' && (
                        <MediaFileUpload
                            onChange={handleFileChange}
                            inputProps={{
                                accept: ['image/png', 'image/jpg', 'image/jpeg'].join(', '),
                                multiple: true,
                            }}
                            children={
                                <Typography variant="body2" color="text.secondary">
                                    PNG, JPG, or JPEG
                                </Typography>
                            }
                        />
                    )}
                    {campaign.mediaType === 'Audio' && (
                        <MediaFileUpload
                            onChange={handleFileChange}
                            inputProps={{
                                accept: ['audio/mp3'].join(', '),
                                multiple: true,
                            }}
                            children={
                                <Typography variant="body2" color="text.secondary">
                                    MP3
                                </Typography>
                            }
                        />
                    )}
                </>
            )}

            {fileCount > 0 && (
                <List disablePadding sx={{ width: '100%' }} dense>
                    {files.map((f) => (
                        <FileListItem
                            key={`${f.name}:${f.size}`}
                            f={f}
                            removeFile={removeFile}
                            downloadFile={downloadFile}
                            maxFileSize={MAX_FILE_SIZE}
                        />
                    ))}

                    {existingFiles.map((f) => (
                        <FileListItem
                            complete
                            key={`${f.name}:${f.size}`}
                            f={f}
                            removeFile={removeFile}
                            downloadFile={downloadFile}
                            maxFileSize={MAX_FILE_SIZE}
                        />
                    ))}
                </List>
            )}
        </Column>
    );
}
