import { Box, Button, Typography } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Campaign } from '../../../../types/Campaign';
import { FlyingAstronautMascot } from '../../../../components/AstronautMascot';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

type CampaignWizardSubmissionConfirmationProps = {
    campaign: Campaign;
};

export default function CampaignWizardSubmissionConfirmation(props: CampaignWizardSubmissionConfirmationProps) {
    const navigate = useNavigate();
    const { resetCampaignWizard } = useCampaignWizardContext();

    return (
        <Box sx={{ mx: 3, my: 9 }}>
            <FlyingAstronautMascot />

            <Column gap={3} justifyContent="center" alignItems="center" sx={{ p: 5 }}>
                <CheckCircleIcon color="success" sx={{ fontSize: 72 }} />

                <Column gap={1} justifyContent="center" alignItems="center">
                    <Typography variant="h6" sx={{ fontSize: 20 }}>
                        Success! Your campaign has been submitted for review.
                    </Typography>

                    <Typography variant="body2">
                        You will receive an email notification from Cognition Support once your campaign has been
                        approved.
                    </Typography>
                </Column>

                <Row gap={1} justifyContent="center" alignItems="center">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            resetCampaignWizard();
                            window.history.pushState(null, '', '');
                            navigate(`/campaign/v2/create`);
                        }}
                    >
                        Create New Campaign
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            window.history.pushState(null, '', '');
                            navigate(`/campaignManager`);
                        }}
                    >
                        Go to Campaigns
                    </Button>
                </Row>
            </Column>
        </Box>
    );
}
