import { useCallback, useEffect, useRef, useState } from 'react';

import { Campaign, CampaignAdGroupInventorySource } from '../types/Campaign';
import { Dealer } from '../types/Dealer';
import { InventorySource } from '../types/Inventory';
import ApiService from '../ApiService';

export function toCampaignAdGroupInventorySources(
    inventorySources: InventorySource[],
    adGroupInventorySources: CampaignAdGroupInventorySource[] = []
): CampaignAdGroupInventorySource[] {
    return [
        ...adGroupInventorySources
            .filter((x: CampaignAdGroupInventorySource) => {
                return inventorySources.find((y: InventorySource) => x.dspInventoryId === y.id) !== undefined;
            })
            .map((x: CampaignAdGroupInventorySource) => {
                const y: InventorySource | undefined = inventorySources.find(
                    (y: InventorySource) => x.dspInventoryId === y.id
                );
                if (y !== undefined) {
                    x.inventorySourceType = y.inventorySourceType;
                }
                return x;
            }),
        ...inventorySources
            .filter((x: InventorySource) => {
                return (
                    adGroupInventorySources.find((y: CampaignAdGroupInventorySource) => x.id === y.dspInventoryId) ===
                    undefined
                );
            })
            .map((inventorySource: InventorySource) => {
                return {
                    dspInventoryId: inventorySource.id,
                    inventorySourceType: inventorySource.inventorySourceType as string,
                };
            }),
    ];
}

export function toInventorySources(adGroupInventorySources: CampaignAdGroupInventorySource[]): InventorySource[] {
    let inventorySources: InventorySource[] = [];

    if (adGroupInventorySources.length > 0) {
        inventorySources.push(
            ...adGroupInventorySources.map((adGroupInventorySource: CampaignAdGroupInventorySource) => {
                return {
                    id: adGroupInventorySource.dspInventoryId,
                    deviceType: '',
                    inventorySourceType: adGroupInventorySource.inventorySourceType as string,
                    inventoryType: '',
                    name: '',
                };
            })
        );
    }

    return inventorySources;
}

export interface InventorySources {
    //
    inventorySources: InventorySource[];
    setInventorySources(inventorySources: InventorySource[]): void;
    fetchInventorySources(): void;

    //
    getInventorySourceName(inventorySource: InventorySource): string;
    getCampaignInventorySources(campaign: Campaign): InventorySource[];

    //
    hydrateInventorySource(unhydratedInventorySource: InventorySource): InventorySource;
    hydrateInventorySources(unhydratedInventorySources: InventorySource[]): InventorySource[];
}

type InventorySourcesProps = {
    dealer: Dealer | null | undefined;
};

export default function useInventorySources(props: InventorySourcesProps): InventorySources {
    const { dealer } = props;
    const [inventorySources, setInventorySources] = useState<InventorySource[]>([]);
    const isFetched = useRef<boolean>(false);

    const fetchInventorySources = useCallback(() => {
        if (dealer) {
            if (isFetched.current === false) {
                ApiService.getGatewayInventories(dealer.dspAdvertiser).then((response) => {
                    const {
                        data: { data },
                    } = response;
                    const exchanges: InventorySource[] = [];

                    if (data?.deals?.length > 0) {
                        exchanges.push(
                            ...data.deals.map((d: any) => {
                                if (d?.dealPrice?.amount) {
                                    d.dealPrice.amount /= Math.pow(10, 5);
                                }

                                return {
                                    id: d.id,
                                    name: d.name,
                                    inventoryType: d.inventoryType,
                                    inventorySourceType: 'DEAL',
                                    deviceType: null,
                                    deal: d,
                                };
                            })
                        );
                    }

                    if (data?.exchanges?.inventory?.length > 0) {
                        exchanges.push(
                            ...data.exchanges.inventory.map((inventorySource: InventorySource) => {
                                if (inventorySource?.inventorySourceType === 'AMAZON_PUBLISHER_DIRECT') {
                                    inventorySource.inventorySourceType = 'APD';
                                }
                                return inventorySource;
                            })
                        );
                    }

                    setInventorySources(exchanges);
                });
            }

            isFetched.current = true;
        } else {
            setInventorySources([]);
        }
    }, [dealer]);

    const getInventorySourceName = (inventorySource: InventorySource): string => {
        return hydrateInventorySource(inventorySource)?.name;
    };

    const hydrateInventorySource = (unhydratedInventorySource: InventorySource): InventorySource => {
        if (inventorySources.length > 0) {
            const hydratedInventorySource = inventorySources.find(
                (o: InventorySource) => o.id === unhydratedInventorySource.id
            );

            if (hydratedInventorySource) {
                return hydratedInventorySource;
            }
        }

        return unhydratedInventorySource;
    };

    const hydrateInventorySources = (unhydratedInventorySources: InventorySource[]): InventorySource[] => {
        if (inventorySources.length > 0) {
            return unhydratedInventorySources.map((unhydratedInventorySource: InventorySource) =>
                hydrateInventorySource(unhydratedInventorySource)
            );
        }

        return unhydratedInventorySources;
    };

    const getCampaignInventorySources = (campaign: Campaign): InventorySource[] => {
        let hydratedInventorySources: InventorySource[] = [];

        if (campaign.adGroups.length > 0) {
            if (campaign.adGroups[0]?.adGroupInventorySources) {
                hydratedInventorySources = hydrateInventorySources(
                    toInventorySources(campaign.adGroups[0].adGroupInventorySources)
                );
            }
        }

        return hydratedInventorySources;
    };

    // useEffect(() => {
    //     fetchInventorySources();
    // }, []);

    useEffect(() => {
        isFetched.current = false;
    }, [dealer]);

    return {
        inventorySources,
        setInventorySources,
        fetchInventorySources,
        getInventorySourceName,
        hydrateInventorySource,
        hydrateInventorySources,
        getCampaignInventorySources,
    };
}
