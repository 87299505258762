import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import Map from '../../../components/Map';
import { Place } from '@mui/icons-material';
import { ZipCode } from '../../../components/Map/Map';

export type { ZipCode };

const boundaryStyles = {
    'background-color': '#1fbdf6',
    opacity: 0.5,
    'line-color': '#0004ae',
    'line-opacity': 0.6,
    selected: {
        'background-color': 'yellow',
        'line-color': '#0004ae',
        'line-opacity': 0.6,
    },
};

export default function MapComponent(props: {
    zipCode: string;
    zipCodes: string;
    radius: number;
    onZipsLoaded(results: ZipCode[]): void;
    onZipClick(zip: ZipCode): void;
    reuseMaps?: boolean;
}) {
    const { onZipsLoaded, onZipClick, radius, zipCode } = props;
    const [token, setToken] = useState<string>('');

    useEffect(() => {
        axios.get('/api/geographicTargeting/token').then((response) => {
            setToken(response.data);
        });
    }, []);

    const getCenterPointByGeocoder = useCallback(async () => {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${props.zipCode}.json?limit=1&proximity=ip&access_token=${token}`
        );
        const data = await response.json();
        if (data.features.length > 0) {
            return { center: data.features[0].center };
        }

        // USA
        return { center: [-97.9222112121185, 39.3812661305678] };
    }, [props.zipCode, token]);

    return (
        <Map
            reuseMaps={props.reuseMaps}
            baseUrl="/api/geographicTargeting"
            onZipClick={onZipClick}
            onZipsLoaded={onZipsLoaded}
            code={zipCode}
            zipCodes={props.zipCodes ?? ''}
            radius={radius}
            displayBoundariesOnStart={true}
            mapboxAccessToken={token}
            marker={
                <Tooltip title={zipCode}>
                    <Place color="error" fontSize="large" />
                </Tooltip>
            }
            getCenterPoint={getCenterPointByGeocoder}
            boundaryStyles={boundaryStyles}
        />
    );
}
