import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import Row from './Row';

export default function ImagePreviewDialog(props: {
    open: boolean;
    onClose(): void;
    DialogTitle?: any;
    imageUrl: string;
}) {
    const [open, setOpen] = useState<boolean>(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
        setOpen(false);

        props.onClose();
    };

    return (
        <Dialog maxWidth="md" open={open} fullWidth onClose={handleClose}>
            <DialogTitle>{props.DialogTitle ?? 'Image Preview'}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                <Row alignItems="center" justifyContent="center">
                    <img src={props.imageUrl} alt="" />
                </Row>
            </DialogContent>
        </Dialog>
    );
}
