import { SectionHeading } from '../../components/SectionHeading';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../App';
import ApiService from '../../ApiService';
import { Campaign, CampaignCriteria, CampaignStatusCount } from '../../types/Campaign';
import Utils from '../../components/Utils';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import CampaignStatusFilter from './CampaignStatusFilter';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import CampaignManagerGrid from './CampaignManagerGrid';
import CampaignManagerDataGridExportCsvButton from './CampaignManagerDataGridExportCsvButton';
import CampaignMonthSelect from './CampaignMonthSelect';
import CampaignIncludePendingSwitch from './CampaignIncludePendingSwitch';
import { AdvertiserContext } from '../../AdvertiserContext';
import { Link, useNavigate } from 'react-router-dom';
import Row from '../../components/Row';
import theme from '../../theme';
import { CampaignManagerPageContext } from '../../hooks/useCampaignManagerPage';
import useCampaignManagerPage from '../../hooks/useCampaignManagerPage';
import AccessGuard from '../../components/AccessGuard';
import { USER_GROUP_ADMIN } from '../../types/User';

const PAGE = 'campaignManager.';
const PAGE_SIZE = 10;

export default function CampaignManagerPageV2() {
    const navigate = useNavigate();

    const campaignManagerPage = useCampaignManagerPage();
    const { setCampaignGridColumnVisibility } = campaignManagerPage;

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [campaignStatusCounts, setCampaignStatusCounts] = useState<CampaignStatusCount[]>([]);
    const { userContext } = useContext(UserContext);
    const { advertiserContext } = useContext(AdvertiserContext);

    const [campaignCriteria, setCampaignCriteria] = useState<CampaignCriteria>(() => {
        let criteria = Utils.localStorage(PAGE + 'campaignCriteria', null);
        if (criteria) {
            let value = JSON.parse(criteria);
            value.startDate = new Date(value.startDate);
            value.endDate = new Date(value.endDate);
            value.includePendingCampaigns = false;
            return value;
        }
        return {
            startDate: Utils.getMonthStart(0),
            endDate: Utils.getMonthEnd(0),
            deliveryStatusId: '',
            includePendingCampaigns: false,
        };
    });

    useEffect(() => {
        const criteria = {
            ...campaignCriteria,
            startDate: campaignCriteria.startDate.getTime(),
            endDate: campaignCriteria.endDate.getTime(),
        };
        const value = JSON.stringify(criteria);
        localStorage.setItem(PAGE + 'campaignCriteria', value);
    }, [campaignCriteria]);

    const prevCampaignCriteria = useRef(campaignCriteria);
    useEffect(() => {
        campaignCriteria.dealerIds =
            advertiserContext?.dealer && advertiserContext.dealer.id ? [advertiserContext.dealer.id] : null;
        campaignCriteria.agencyId = advertiserContext?.agencyId ? advertiserContext.agencyId : undefined;
        campaignCriteria.pageSize = PAGE_SIZE;

        ApiService.getCampaigns(campaignCriteria).then((response) => {
            setCampaigns(response.data);
        });

        const ignoredKeys = ['page', 'deliveryStatusId', 'sort'];
        const ignoredChangesOnly =
            ignoredKeys.some((key) => prevCampaignCriteria.current[key] !== campaignCriteria[key]) &&
            Object.keys(prevCampaignCriteria.current).every(
                (key) => ignoredKeys.includes(key) || prevCampaignCriteria.current[key] === campaignCriteria[key]
            );

        if (!ignoredChangesOnly) {
            ApiService.getCampaignStatusCounts(campaignCriteria).then((response) => {
                const data = response.data;

                const roleStatusCounts = userContext.isAdmin()
                    ? data
                    : data.filter((item) => item.statusId !== 'PENDING_COGNITION');

                setCampaignStatusCounts(roleStatusCounts);
            });
        }
        prevCampaignCriteria.current = campaignCriteria;
    }, [advertiserContext.dealer, advertiserContext.agencyId, campaignCriteria]);

    const [searchText, setSearchText] = useState(campaignCriteria.searchText);
    const [searchTextLoading, setSearchTextLoading] = useState(false);

    const debounce = (func: (...args: any[]) => any, delay: number) => {
        let timeoutId: any;
        return (...args: any[]) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedChangeSearchCriteria = useRef(
        debounce((campaignCriteria, value) => {
            setSearchTextLoading(false);
            changeSearchCriteria({ ...campaignCriteria, searchText: value, deliveryStatusId: '' });
        }, 1000)
    ).current;

    useEffect(() => {
        if (searchText === campaignCriteria.searchText) return;
        setSearchTextLoading(true);
        debouncedChangeSearchCriteria(campaignCriteria, searchText);
    }, [searchText]);

    function changeSearchCriteria(campaignCriteria: CampaignCriteria) {
        setCampaignCriteria({ ...campaignCriteria });
    }

    function clearSearch() {
        setSearchText('');
        localStorage.setItem(PAGE + 'campaignSearch', '');
    }

    function onPageChange(gridPage: number) {
        if (gridPage === campaignCriteria.page) return;
        setCampaignCriteria({ ...campaignCriteria, page: gridPage });
    }

    function onSortModelChange([order]: any) {
        if (!order) order = { field: 'dealer', sort: 'asc' };
        let { field, sort } = order;
        if (field === 'dealer') field = 'dealerName';
        if (field === 'deliveryStatus') field = 'deliveryStatusId';
        if (field === 'agency') field = 'agencyName';
        if (field === 'createdByUser') field = 'createdByUserName';
        setCampaignCriteria({ ...campaignCriteria, sort: `${field} ${sort}` });
    }

    return (
        <CampaignManagerPageContext.Provider value={campaignManagerPage}>
            <Box
                id="campaignManagerPage"
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <Stack
                    sx={{
                        width: '100%',
                    }}
                    direction="column"
                    spacing={3}
                >
                    <Row justifyContent="space-between" alignItems="center" spacing={2}>
                        <Box>
                            <SectionHeading
                                subheading={'This is the central hub where you monitor and modify your campaigns.'}
                                divider={false}
                            >
                                <Row spacing={1} alignItems="center">
                                    <Typography variant="h6">Campaigns</Typography>
                                </Row>
                            </SectionHeading>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                disableElevation
                                size="large"
                                component={Link}
                                to="/campaign/create"
                                sx={{ height: 40 }}
                            >
                                Create Campaign
                            </Button>
                            {userContext.isAdmin() && (
                                <Button
                                    variant="contained"
                                    disableElevation
                                    size="large"
                                    component={Link}
                                    to="/campaign/v2/create"
                                    sx={{ height: 40, ml: 3 }}
                                >
                                    Create Campaign (v2)
                                </Button>
                            )}
                        </Box>
                    </Row>

                    <CampaignStatusFilter
                        campaignStatusCounts={campaignStatusCounts}
                        selectedStatus={campaignCriteria.deliveryStatusId}
                        onClick={(value: string) => {
                            changeSearchCriteria({
                                ...campaignCriteria,
                                deliveryStatusId: value,
                            });
                        }}
                    />

                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                        <Row gap={2}>
                            <TextField
                                sx={{
                                    height: 40,
                                    width: 320,
                                    '& .MuiInputBase-input': {
                                        backgroundColor: campaignCriteria.searchText ? '#F2F5FF' : '',
                                        color: theme.palette.text.secondary,
                                        fontSize: theme.typography.fontSize,
                                        height: '1.64375em',

                                        '&::placeholder': {
                                            color: theme.palette.text.primary,
                                        },
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: campaignCriteria.searchText
                                            ? `${theme.palette.primary.main} !important`
                                            : '',
                                    },
                                }}
                                id="searchText"
                                value={searchText}
                                autoComplete="off"
                                size="small"
                                placeholder="Search Campaigns"
                                onChange={(event) => {
                                    setSearchText(event.target.value);
                                }}
                                InputProps={{
                                    startAdornment:
                                        campaignCriteria.searchText?.length > 0 ? (
                                            ''
                                        ) : (
                                            <InputAdornment position="start">
                                                <Tooltip
                                                    title={
                                                        userContext.isAdmin()
                                                            ? 'Search by DSP Campaign ID, PO Number, Advertiser, Campaign Name, Media, Campaign, or Strategy Type'
                                                            : 'Search by Advertiser, Media, Campaign, or Strategy'
                                                    }
                                                >
                                                    <SearchOutlined fontSize="small" color="action" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    endAdornment: searchTextLoading ? (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearSearch()} edge="end">
                                                <CircularProgress color="inherit" size={20} />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : campaignCriteria.searchText?.length > 0 ? (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearSearch()} edge="end">
                                                <ClearOutlined fontSize="small" color="action" />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : (
                                        ''
                                    ),
                                }}
                            />
                            <CampaignMonthSelect
                                lastNthMonths={6}
                                campaignCriteria={campaignCriteria}
                                changeSearchCriteria={(criteria: any) => changeSearchCriteria({ ...criteria })}
                            />

                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <CampaignIncludePendingSwitch
                                    campaignCriteria={campaignCriteria}
                                    changeSearchCriteria={(criteria: any) => changeSearchCriteria({ ...criteria })}
                                />
                            </AccessGuard>
                        </Row>

                        <CampaignManagerDataGridExportCsvButton campaignCriteria={campaignCriteria} />
                    </Stack>
                    <CampaignManagerGrid
                        campaigns={campaigns}
                        totalCampaigns={
                            campaignStatusCounts.find((c) => c.statusId === campaignCriteria.deliveryStatusId)?.count ||
                            0
                        }
                        currentPage={campaignCriteria.page}
                        campaignCriteria={campaignCriteria}
                        deliveryStatusId={campaignCriteria.deliveryStatusId}
                        onPageChange={onPageChange}
                        onSortModelChange={onSortModelChange}
                        onShowDetails={(campaign: Campaign) => {
                            navigate('' + campaign.id);
                        }}
                        onGridColumnVisibilityChange={(gridColumnVisibility) => {
                            setCampaignGridColumnVisibility(gridColumnVisibility);
                        }}
                    />
                </Stack>
            </Box>
        </CampaignManagerPageContext.Provider>
    );
}
