import { SyntheticEvent, useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Card } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { Location, ZipCode } from '../../../../types/Location';
import { parseZipCodes } from '../../../../components/Location/ZipCodeList';
import CampaignLocationTab from './CampaignLocationTab';
import CampaignLocationZipCodeTab from './CampaignLocationZipCodeTab';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

export const TABS = {
    LOCATIONS: 0,
    ZIPCODES: 1,
};

type CampaignTargetingLocationTabsProps = {} & CampaignWizardStepProps;

export default function CampaignTargetingLocationTabs(props: CampaignTargetingLocationTabsProps) {
    const { campaign, onChange } = props;

    const [activeTab, setActiveTab] = useState<number>(0);
    const [itemCount, setItemCount] = useState<number>(0);

    const [locations, setLocations] = useState<Location[]>([]);
    const [zipCodes, setZipCodes] = useState<ZipCode[]>([]);

    useEffect(() => {
        switch (activeTab) {
            case TABS.LOCATIONS:
                setItemCount(locations.length);
                break;

            case TABS.ZIPCODES:
                setItemCount(zipCodes.length);
                break;
        }
    }, [activeTab, locations, zipCodes]);

    useEffect(() => {
        setZipCodes(parseZipCodes(campaign.zipcodes));
    }, [campaign.zipcodes]);

    const handleTabChange = (event: SyntheticEvent, value: any) => {
        setActiveTab(value);
    };

    return (
        <Box>
            <Card
                variant="outlined"
                sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Column>
                            <Box>
                                <Tabs value={activeTab} onChange={handleTabChange}>
                                    <Tab value={TABS.LOCATIONS} label="Search Locations" />
                                    <Tab value={TABS.ZIPCODES} label="Enter ZIP Codes" />
                                </Tabs>
                            </Box>
                        </Column>
                    </Grid>
                    <Grid item xs={6} sx={{ borderLeft: 1, borderColor: 'divider' }}>
                        <Box
                            sx={{
                                px: 2,
                                py: 3,
                                height: 48,
                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Row alignItems="center" sx={{ height: 1 }}>
                                {itemCount > 0 && (
                                    <Typography variant="caption" color="text.secondary">
                                        {`${itemCount} selected`}
                                    </Typography>
                                )}
                                {itemCount === 0 && (
                                    <Typography variant="caption" color="text.secondary">
                                        None selected
                                    </Typography>
                                )}
                            </Row>
                        </Box>
                    </Grid>
                </Grid>
            </Card>

            <Column>
                {activeTab === TABS.LOCATIONS && (
                    <CampaignLocationTab
                        campaign={campaign}
                        onChange={onChange}
                        locations={locations}
                        onChangeLocation={(items: Location[]): void => {
                            setLocations(items);
                        }}
                    />
                )}
                {activeTab === TABS.ZIPCODES && (
                    <CampaignLocationZipCodeTab
                        campaign={campaign}
                        onChange={onChange}
                        zipCodes={zipCodes}
                        targetZipCodes={locations.map((location: Location) => location.zipCode).join(',')}
                        onChangeZipCodes={(items: ZipCode[]): void => {
                            setZipCodes(items);
                        }}
                    />
                )}
            </Column>
        </Box>
    );
}
